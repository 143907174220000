@import '../../sass/variables';

.PaginationContainer {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;

  .PaginationButton {
    display: flex;
    margin-left: auto;

    button {
      border-radius: 50px;
      margin-left: 10px;
    }
  }

  .PaginationItem {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    color: $default-color;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $light-gray;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    cursor: pointer;

    &.Dots:hover {
      border: 1px solid $light-gray;
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      border: 1px solid $dark-gray;
      cursor: pointer;
    }

    &.Selected {
      color: $white;
      background-color: $hover-blue;
      border: none;
    }
  }
}

@import '../../../../../sass/variables';
@import '../../../../../sass/mixins';

.BrokerLicenses {
  &Body {
    min-height: 300px;

    &Table {
      @include styled-table;

      td {
        div {
          max-width: 300px;
          margin-bottom: 0 !important;
        }
      }

      &Edit {
        display: flex;

        button {
          height: 30px;
          width: 70px;
          margin-right: 10px;
        }

        i {
          display: flex;
          align-items: center;
          margin-left: 10px;
          color: $danger-red;
          cursor: pointer;
        }
      }

      tbody tr:nth-child(even) {
        background: #fafafa;
      }

      &EmptyField {
        height: 25px;
      }

      &State {
        width: 190px;
      }
    }
  }

  &Footer {
    display: flex;

    &Save {
      display: flex;
      margin-left: 10px;

      button {
        margin-left: 10px;

        width: 90px !important;
      }
    }

    button {
      width: 165px;
    }
  }
}

.EditIcon {
  color: $light-blue !important;
  cursor: pointer;
  margin-left: auto !important;
}

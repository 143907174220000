@import '../sass/variables';
@import '../sass/mixins';

.App {
  width: 100%;
  text-align: center;
  position: relative;
}

.ReadOnlyContent {
  align-items: flex-start !important;
  flex-direction: column;
  margin-top: 50px !important;
  @include container;
}

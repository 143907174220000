@import '../../../sass/mixins';
@import '../../../sass/variables';

.CoverageForm {
  &Quotes {
    display: flex;
    @media screen and (max-width: 920px) {
      flex-direction: column;
    }
    &Item {
      @include column;

      @media screen and (max-width: 1215px) {
        max-width: 255px;
      }

      @media screen and (max-width: 920px) {
        max-width: unset;
      }

      div {
        margin-top: 0;
        margin-bottom: 0;
      }

      &Buttons {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          height: 40px;

          &:nth-of-type(1) {
            width: 110px;
            margin-right: 10px;
            color: $hover-blue;
            border: 1px solid $hover-blue;

            &:hover {
              color: $white;
              background-color: $hover-blue;
              border: 1px solid $hover-blue;
            }
          }
        }
      }
    }
  }
  &Toggle {
    &Wrapper {
      display: flex;
      align-items: center;
      padding: 5px 0;
      margin-left: 15px;
    }

    &Title {
      font-weight: 500;
      margin-right: 10px;
    }

    &Icon {
      margin-left: 10px;
    }
  }

  &Contribution {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding-left: 12px;
  }
}

.CarrierSelect {
  height: 33px;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 5px 15px;
  outline: none;
  position: relative;
  cursor: pointer;

  &Asterisc {
    color: $danger-red;
  }

  &Arrow,
  &ArrowActive {
    position: absolute;
    right: 5px;
    height: 6px;
  }

  &ArrowActive {
    transform: rotate(180deg);
  }

  &Dropdown {
    width: 400px;
    max-height: 350px;
    overflow-y: auto;
    background-color: $white;
    border-radius: 4px;
    position: absolute;
    top: 33px;
    left: 0;
    z-index: 1;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }

  &Item {
    display: flex;
    align-items: center;
    padding: 6px 48px 6px 16px;

    &Wrapper {
      div:hover {
        background-color: unset;
      }
    }

    &Logo {
      height: 25px;
      width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      img {
        height: 25px;
        width: 75px;
        object-fit: contain;
      }
    }

    &Name {
      text-align: left;
      font-size: 14px;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.CarrierSelected {
  &Item {
    display: flex;
    align-items: center;

    &Wrapper {
      div:hover {
        background-color: unset;
      }
    }

    &Logo {
      height: 25px;
      width: 75px;
      margin-right: 10px;

      img {
        height: 25px;
        width: 75px;
        object-fit: contain;
      }
    }

    &Name {
      width: 180px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media screen and (max-width: 920px) {
        width: unset;
        text-overflow: unset;
        white-space: unset;
        overflow: unset;
      }
    }
  }
}

.CarrierSelectWrapper {
  display: flex;
  flex-direction: column;

  label {
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.ViewPlans {
  display: flex;
  align-items: center;

  flex-direction: row;
  margin: 10px 0px;

  &Name {
    display: flex;
    width: 100%;
    max-width: 450px;
  }

  &Total {
    margin-left: 10px;
  }

  &Button {
    display: flex;
    align-items: center;

    &Item {
      margin-left: 10px;
      font-size: 14px;
      i {
        color: #2ecf00;
      }
    }

    &Base {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    button {
      height: 40px;
      width: 110px;

      &:hover {
        color: $white;
        background-color: $hover-blue;
        border: 1px solid $hover-blue;

        div {
          div {
            border-top-color: $white !important;
            border-right-color: $white !important;
          }
        }
      }
    }
  }
}

.ToggleWrapper {
  display: flex;
  margin-bottom: 12px;
}

@import '../../../../../sass/mixins';
@import '../../../../../sass/variables';

.AgencyPendingInvites {
  width: 100%;
  font-size: 14px;

  &Title {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    margin: 24px 0;
  }

  &PageHeader {
    margin-bottom: 15px;
  }

  &User {
    display: flex;
    align-items: center;
    margin-bottom: 57px;

    &Wrapper {
      display: flex;
      align-items: center;
    }

    &Image {
      height: 48px;
      width: 48px;
      border-radius: 50%;

      &Wrapper {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ccc;
        border-radius: 50%;
        border: 2px solid $monark-main-color;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
      }
    }

    &Name {
      text-transform: uppercase;
    }

    &Email {
      text-transform: none !important;
    }

    &Status {
      display: flex;

      button {
        height: 27px;
        width: 125px;
        margin-left: 10px;
      }
    }
  }

  &Wrapper {
    overflow-y: auto;
  }

  &Permission {
    text-transform: capitalize;
    div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &TableItemWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &TrashIcon {
    color: red;
    font-size: 18px;
    cursor: pointer;
  }
}

.ModalContent {
  &Title,
  &Name,
  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Title {
    margin-bottom: 16px;
  }

  &Name {
    font-weight: 600;
  }

  &Footer {
    width: 100%;
    margin-top: 16px;

    button {
      width: 100px;
      margin: 0 10px;
    }
  }
}

@import '../../sass/variables';

.DetailsItem {
  &Title,
  &Value,
  &TrashIcon,
  &ContactType {
    border-bottom: 1px solid #dbdbdb;
    text-align: left;
    padding: 7px 10px;
    font-size: 14px;
  }

  &Title {
    max-width: 200px;
    word-break: break-word;
    font-weight: 500;
  }

  &Value,
  &ContactType {
    word-break: break-word;
  }

  &ContactType {
    text-transform: capitalize;
  }

  &TrashIcon {
    font-size: 20px;
    color: $dark-gray;
    cursor: pointer;
  }
}

@import '../../sass/variables';

.Loader,
.LoaderSmall {
  height: 120px;
  width: 120px;
  border: 4px solid transparent;
  border-top: 4px solid $monark-main-color;
  border-right: 4px solid $monark-main-color;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

.LoaderSmall {
  height: 20px;
  width: 20px;
  border: 2px solid transparent;
  border-top: 2px solid $monark-main-color;
  border-right: 2px solid $monark-main-color;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LoaderWrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

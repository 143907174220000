@import '../sass/mixins';
@import '../sass/variables';

.RouteWrapper {
  padding: 48px 36px;
}

.RouteWrapperContent {
  align-items: flex-start !important;
  flex-direction: column;
  @include container;
}

.ButtonsWrapper {
  display: flex;
  justify-content: flex-start;

  button {
    width: 100%;
    max-width: 82px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.clear {
  border-bottom: none !important;

  span,
  tr,
  td {
    border: none !important;
    border-bottom: none !important;
  }

  td {
    vertical-align: middle;
  }
}

.AddOffer {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;

  button {
    max-width: 100px;
  }
}

.DeclineButton {
  button {
    border: 1px solid red;
    color: red;
    &:hover {
      border: 1px solid #c00000;
      color: #c00000;
    }
  }
}

.ExtraRow {
  display: flex;
  align-items: center;

  input {
    width: 100%;
  }

  i {
    margin-left: 10px;
    color: #c00000;
    cursor: pointer;
  }
}

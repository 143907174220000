@import '../../../../../sass/mixins';

.DashboardReps {
  margin-bottom: 29px;

  &Title {
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 9px;
  }
}

@import '../../../sass/mixins';
@import '../../../sass/variables';

.Contacts {
  height: 100%;
  width: 100%;
  min-height: 600px;

  &UpdateBilling {
    margin-top: 24px;
    text-align: left;

    &Link {
      text-decoration: none;

      color: $hover-blue;

      &:hover {
        color: $default-color;
      }
    }
  }

  &Title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
  }

  &Header {
    display: flex;
  }

  &Search {
    max-width: 210px;
    display: flex;
    margin-right: 15px;

    &Wrapper {
      position: relative;
    }

    &Clear {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%) rotate(45deg);
      cursor: pointer;
      font-size: 14px;
    }

    div {
      margin-bottom: 0;
    }

    input {
      height: 35px;
      font-size: 14px;
      border-radius: 4px 0 0 4px;
    }

    button {
      width: 45px;
      height: 35px;
      border-radius: 0 4px 4px 0;

      i {
        margin: 0;
      }
    }
  }

  &Create {
    display: flex;
    button {
      width: 75px;
      height: 35px;
    }

    div {
      margin-top: 0;
      margin-bottom: 0;

      input {
        width: 100%;
        height: 35px;
        max-width: 150px;
        margin-right: 10px;
      }
    }

    &Option {
      margin-right: 15px;
    }
  }

  &Table {
    min-height: 630px;
  }
}

.ModalContent {
  padding: 0 40px;
  &Header {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 24px;
  }

  &Footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    button {
      width: 130px;
      height: 35px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@import '../../../../../sass/variables';

.ProposalHeaderInfo {
  &Title {
    font-size: 18px;
    font-weight: 700;
  }

  &Input {
    margin-right: 5px;
    margin-left: 15px;

    input {
      min-width: 140px;
    }

    div {
      label {
        margin-bottom: 0 !important;
      }
    }
  }

  &Wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    div {
      margin-bottom: 0;
    }
  }

  &Buttons {
    display: flex;
    margin-left: auto;

    button {
      height: 35px;
      width: 130px;
      margin-left: 10px;
    }
  }

  &Status {
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }

  &Item {
    margin-left: 15px;
    &Title {
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    &SubTitle {
      text-align: left;
      text-transform: capitalize;
      color: #363636;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      word-wrap: break-word;
    }
  }

  &Arrow,
  &Edit {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
  }

  &Arrow {
    position: absolute;
    left: -30px;
    margin-right: 10px;
    color: $hover-blue;
  }
}

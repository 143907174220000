@import '../../../../../sass/variables';

.PermissionsTable {
  width: 100%;

  &CheckIcon {
    font-size: 18px;
    color: $monark-main-color;
    cursor: pointer;

    &Disabled {
      color: #bb99f9 !important;
      circle {
        fill: #bb99f9 !important;
        stroke: #bb99f9 !important;
      }
    }
  }

  &CircleIcon {
    font-size: 25px;
    color: $monark-main-color;
    cursor: pointer;
  }
}

.ProposalFinalize {
  &Expand {
    margin-bottom: 15px;

    button {
      margin-left: auto;
      max-width: 135px;
    }
  }

  &Footer {
    &Divider {
      height: 1px;
      width: 100%;
      margin: 25px 0 !important;
      background-color: #b1b1b1;
    }
    &Button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@import '../../../sass/mixins';

.AgencyReporting {
  width: 100%;
  align-items: flex-start !important;
  flex-direction: column;
  min-height: 600px;

  &Header {
    display: flex;
  }

  &PageHeader {
    display: flex;
    align-items: center;

    &Datepicker {
      width: 260px;
      margin-left: auto;
      margin-right: 10px;
    }
    &Range {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    &Month,
    &MonthSelected {
      margin-right: 5px;
    }

    &Day,
    &DaySelected {
      margin-left: 5px;
    }

    &MonthSelected,
    &DaySelected {
      font-weight: 500 !important;
      text-decoration: underline;
    }

    &Day,
    &DaySelected,
    &Month,
    &MonthSelected {
      font-weight: normal;
      cursor: pointer;
    }

    &Line {
      font-weight: normal;
      margin-bottom: 5px;
    }
  }

  &Content {
    &Options {
      display: flex;

      &Item {
        width: 100%;
        max-width: 210px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &Chart {
    position: relative;

    canvas {
      width: 100% !important;
      height: 100% !important;
      padding: 10px;
    }

    &Loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #cccccc67;
    }
  }
}

.Dataset {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;

  &Legend {
    display: flex;
    align-items: center;
    margin: 5px 10px 5px 0;
    cursor: pointer;

    &:last-child {
      margin-right: 0px;
    }

    &Color {
      height: 12px;
      width: 40px;
      margin-right: 6px;
    }

    &Label {
      font-size: 14px;
    }
  }
}

.CustomDatepickerButton {
  min-width: 200px;
  height: 34px;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: #209cee;
  color: #fff;
  cursor: pointer;

  &Secondary {
    min-width: 200px;
    height: 34px;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    outline: none;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    cursor: pointer;
  }
}

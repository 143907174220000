.MenuBtn {
  display: none;
}

@media screen and (max-width: 1000px) {
  .MenuBtn {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &Burger {
      width: 24px;
      height: 2px;
      background-color: #363636;
      border-radius: 4px;
      transition: all 0.1s ease-in-out;
      margin: 2px 0;

      &FirstOpen,
      &ThirdOpen {
        width: 24px;
        height: 2px;
        position: absolute;
        background-color: #363636;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
        transform: rotate(45deg);
      }

      &ThirdOpen {
        transform: rotate(-45deg);
      }
    }
  }
}

@import '../common/';

@mixin container {
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  transition: all 0.3s;

  @media screen and (min-width: 1024px) {
    /* big landscape tablets, laptops, and desktops */
    max-width: 960px;
  }

  // @media screen and (min-width: 1216px) {
  //   /* hi-res laptops and desktops */
  //   max-width: 1152px;
  // }

  @media screen and (min-width: 1440px) {
    /* hi-res laptops and desktops */
    max-width: 1440px;
  }
}

@mixin main-adaptive {
  margin: 0 auto;
  transition: all 0.3s;

  @media screen and (min-width: 1024px) {
    /* big landscape tablets, laptops, and desktops */
    max-width: 960px;
  }

  @media screen and (min-width: 1440px) {
    /* hi-res laptops and desktops */
    max-width: 1440px;
  }
}

@mixin fullhd {
  @media (min-width: #{$large}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin desktop-tablet {
  @media (min-width: #{$small}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$large}) and (min-width: #{$medium}) {
    @content;
  }
}

@mixin laptop-tablet {
  @media (max-width: #{$large}) and (min-width: #{$small}) {
    @content;
  }
}

@mixin laptop-mobile {
  @media (max-width: #{$large}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$medium}) and (min-width: #{$small}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$small}) {
    @content;
  }
}

@mixin tablet-mobile {
  @media (max-width: #{$medium}) {
    @content;
  }
}

@mixin tablet-1000 {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin one-quarter-column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 12px;
  -ms-flex-preferred-size: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -ms-flex-negative: 1;

  @media screen and (min-width: 769px) {
    flex: none;
    width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: none;
  }
}

@mixin half-column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 12px;
  -ms-flex-preferred-size: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -ms-flex-negative: 1;

  @media screen and (min-width: 769px) {
    flex: none;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: none;
  }
}

@mixin column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  -ms-flex-preferred-size: 0;
  -ms-flex-positive: 1;
  -ms-flex-negative: 1;
  -webkit-box-flex: 1;
}

@mixin styled-table {
  table {
    width: 100%;
    color: #363636;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
  }

  table thead td,
  table thead th {
    border-width: 0 0 2px;
    color: #363636;
    background-color: transparent !important;
  }

  table {
    thead {
      td,
      th {
        vertical-align: top;
      }
    }
  }

  table td:not([align]),
  table th:not([align]) {
    text-align: left;
  }

  table td,
  table th {
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: 7px 10px;
    text-transform: capitalize;
  }

  tbody {
    background-color: white;
  }

  tbody tr:nth-child(even) {
    background: #fafafa;
  }
}

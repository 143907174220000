.CustomTable {
  width: 100%;

  tr {
    cursor: pointer;
    &:hover {
      background-color: #efefef;
    }
  }

  &Filters {
    display: flex;

    &Item {
      margin-right: 24px;

      div {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      button {
        height: 40px;
      }
    }
  }

  &Filter {
    margin-left: auto;
    margin-right: 10px;

    &Wrapper {
      margin-right: 10px;
    }
  }

  &Search {
    max-width: 210px;
    display: flex;
    align-items: center;

    &Wrapper {
      position: relative;
    }

    &Clear {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%) rotate(45deg);
      cursor: pointer;
      font-size: 14px;
    }

    div {
      margin-bottom: 0;
    }

    input {
      height: 40px;
      font-size: 14px;
      border-radius: 4px 0 0 4px;
    }

    button {
      width: 45px;
      height: 40px;
      border-radius: 0 4px 4px 0;

      i {
        margin: 0;
      }
    }
  }
}

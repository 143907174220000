.RadioInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;

  input {
    margin: 5px 5px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
  }

  &Item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &Label,
  &LabelDisabled {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
    cursor: pointer;
  }

  &LabelDisabled {
    cursor: not-allowed;
  }

  &Wrapper {
    position: relative;
  }

  &Dollar,
  &Pct {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  &Dollar,
  &Pct {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(0, -50%);
  }

  &Pct {
    left: unset;
    right: 8px;
  }

  &Number,
  &Text {
    height: 24px;
    width: 94px;
    padding: 0 10px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
  }

  &Number::-webkit-outer-spin-button,
  &Number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &Number[type='number'] {
    -moz-appearance: textfield;
  }
}

.tooltip {
  margin-left: 5px;
}

@import '../../sass/variables';

.InputWrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 10px;

  label {
    margin-bottom: 10px;
    white-space: nowrap;
  }
}

.RequiredStart {
  color: red;
}

.InputContent {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &Dollar {
    width: 10px !important;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translate(0, -50%);

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    z-index: 1;
  }

  &Percentage {
    width: 10px !important;
    position: absolute;
    left: 162px;
    top: 50%;
    transform: translate(0, -50%);

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  &Title {
    top: 50%;
    left: 140px;
    width: 10px !important;
    position: absolute;
    transform: translate(0, -50%);

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}

.Tooltip {
  margin-left: 5px;
  z-index: 10;
}

.Content {
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.LoaderWrapper {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
}

@import '../../../../../sass/variables';
@import '../../../../../sass/mixins';

.OfferTable {
  padding: 0 20px;

  table,
  td {
    text-transform: unset !important;
  }

  td {
    line-height: 18px;
  }

  img {
    height: 24px;
  }

  &Title {
    width: 250px;
  }

  &Download {
    display: flex;

    &Item {
      display: flex;
      justify-content: center;
      padding: 5px;
      cursor: pointer;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        border-radius: 6px;
        background-color: #ccc;
      }
    }
  }
}

.ExportButton {
  display: flex;
  position: relative;
  margin-left: 17px;

  @media screen and (max-width: 390px) {
    height: 80px;
    flex-direction: column;
  }

  &Csv {
    height: 35px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: black;
    border: 1px solid #dbdbdb;
    text-decoration: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 4px;
    margin-left: 17px;

    &:hover {
      color: $default-color;
      border: 1px solid $dark-gray;
    }
  }

  button {
    height: 35px;
    width: 130px;
  }
}

@import '../../../sass/mixins';
@import '../../../sass/variables';

.Broker {
  height: 100%;
  width: 100%;
  min-height: 600px;

  &UpdateBilling {
    margin-top: 24px;
    text-align: left;

    &Link {
      text-decoration: none;

      color: $hover-blue;

      &:hover {
        color: $default-color;
      }
    }
  }
}

.AccountTableWrapper {
  overflow-y: auto;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding: 0 50px;

  &SubTitle,
  &AgencyName {
    margin-top: 25px;
  }

  &Title {
    font-size: 18px;
    font-weight: 500;
  }

  &AgencyName {
    font-weight: 500;
  }
}

@import '../../sass/variables';
@import '../../sass/mixins';

.Modal,
.ModalLarge,
.ModalXLarge,
.ModalFit,
.ModalAuto {
  min-height: 100px;
  width: 640px;
  background-color: $white;
  max-height: calc(100vh - 40px);
  position: relative;
  // overflow-y: auto;
  z-index: 10;
  transition: all 150ms ease-in-out;

  &Backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &Content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    margin: 0 25px;
  }

  &Wrapper {
    width: 100%;
    padding: 40px 0;
  }

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 25px;
    padding-bottom: 0;
    text-align: left;
  }

  &CloseInside {
    position: sticky;
    top: 20px;
    margin-right: 20px;
    float: right;
    cursor: pointer;
    z-index: 5;
  }

  &Close {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
    right: 30px;
    border-radius: 50%;
    color: $white;
    font-size: 18px;
    cursor: pointer;
    z-index: 5;

    &:hover {
      background-color: #0a0a0a4d;
    }
  }
}

.ModalLarge {
  width: 702px;
}

.ModalXLarge {
  width: 972px;
}

.ModalFit {
  min-height: 550px;
  display: unset !important;
  @include container;
}

.ModalAuto {
  width: unset;
}

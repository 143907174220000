@import '../../sass/variables';
@import '../../sass/mixins';

.CarrierPricing {
  width: 100%;

  @include styled-table;

  table {
    tbody {
      th {
        text-transform: none !important;
        width: 33.33%;
      }

      td {
        width: 33.33%;
        text-align: center !important;
      }
    }
  }

  &Tabs {
    margin-bottom: 15px;
  }

  &Plans {
    width: 100%;
    display: flex;

    &Empty {
      width: 33.33%;
    }

    &Card {
      width: 33.33%;
      display: flex;
      flex-direction: column;

      &Header,
      &Content,
      &Footer {
        padding: 15px;
      }

      &Header {
        border-bottom: 1px solid gray;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
      }

      &Content {
        height: 100%;
        min-height: 70px;
      }

      &Title {
        color: $dark-gray;
        text-align: center;
        font-size: 20px;
        font-weight: bold;

        span {
          font-size: 32px;
          color: black;
        }
      }

      &Footer {
        margin-top: auto;
      }

      button {
        background-color: $monark-main-color;

        &:hover {
          background-color: rgba(121, 51, 243, 0.8);
        }
      }
    }
  }

  &Divider {
    margin: 20px 0;
  }
}

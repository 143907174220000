@import '../../../sass/mixins';
@import '../../../sass/variables';

.Contacts {
  margin-top: 20px;

  button {
    width: 120px;
  }
}

.Modal {
  padding: 0 40px;

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 10px;
  }

  &Content {
    &Option {
      margin-bottom: 10px;
      div {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &Footer {
    display: flex;
    margin-top: 20px;

    button {
      width: 135px;
      margin-right: 10px;
    }
  }
}

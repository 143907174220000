.Dropdown {
  display: flex;
  flex-direction: column;

  &Header {
    display: flex;
    align-items: center;
    margin: 24px 0;

    &Icon {
      margin-right: 10px;
      cursor: pointer;
    }

    &Title {
      color: #4a4a4a;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.25;
    }
  }
}

.Icon {
  &Active {
    transition: all 0.2s ease-in-out;
  }

  &Disabled {
    transition: all 0.2s ease-in-out;
    transform: rotate(-90deg);
  }
}

@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Layout {
  width: 100%;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_left.png');
    max-height: 560px;
    top: 0;
    left: 0;
    z-index: -2;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_left_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_right.png');
    max-height: 560px;
    top: 0;
    right: 0;
    z-index: -3;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_right_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../assets/pages/layoutMain/light_line.png');
    max-height: 560px;
    top: 0;
    left: 0;
    z-index: -1;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/light_line_mobile.png');
    }
  }
}

.ForgotPassword {
  @include container;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobile {
    margin: $indent20;
  }

  img {
    -webkit-user-drag: none;
  }

  &Card {
    width: 100%;
    max-width: 550px;
    min-width: 300px;
    border-radius: 24px;
    background-color: $color-white;
    padding: $indent40 $indent60;
    box-shadow: 0 0 30px 0 #c2c2c2;

    @include mobile {
      padding: $indent40 $indent20;
      border-radius: 16px;
    }
  }

  &Logo {
    display: flex;
    justify-content: center;

    img {
      cursor: pointer;
    }
  }

  form {
    margin-top: $indent24;

    button {
      width: 100%;
      margin-top: $indent14;
    }
  }

  &Copyright {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 144px 24px 24px 24px;
    color: #a9a9a9;
    i {
      margin-right: 5px;
    }
  }
}

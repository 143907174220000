@import '../../../sass/variables';

.Settings {
  width: 100%;
  display: flex;
  margin-top: 20px;
  text-align: left;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &Tabs {
    height: 275px;
    width: 250px;
    padding: 20px;
    border-radius: 5px;
    background-color: $default-gray;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &Title {
      margin-bottom: 12px;
      color: #7a7a7a;
      font-size: 14px;
      text-align: left;
      text-transform: uppercase;
    }

    button {
      width: 100%;
      justify-content: flex-start;
      text-align: left;
    }
  }

  &Content {
    width: 100%;
    max-width: 1096px;
    min-height: 480px;

    &Wrapper {
      margin-top: 20px;
    }
  }

  &Title {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 10px;
  }
}

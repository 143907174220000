@import '../../sass/variables';

.ErrorMessage {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    color: $white;
    background-color: $danger-red;
    border-radius: 4px 4px 0 0;
  }

  &Title {
    font-weight: bold;
  }

  &CloseIcon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #0a0a0a33;
    cursor: pointer;

    i {
      font-size: 14px;
    }

    &:hover {
      background-color: #0a0a0a4d;
    }
  }

  &Content {
    padding: 24px;
    color: $danger-red;
    background-color: #fff5f7;
    text-align: left;
    border-radius: 0 0 4px 4px;
  }
}

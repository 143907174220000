@import '../../sass/variables';
@import '../../sass/mixins';

.QuotesTable {
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;

  @include styled-table;

  tbody tr {
    &:hover {
      background-color: #fafafa;
      cursor: pointer;
    }
  }
}

.Modal {
  &Content {
    padding: 0 24px;

    &MainTable {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 14px;

      tbody tr th:nth-of-type(1) {
        max-width: 200px;
      }
    }

    &Link {
      text-align: left;
      margin: 24px 0;
      font-size: 14px;
      color: $hover-blue;
      cursor: pointer;

      a {
        color: $hover-blue;
        text-decoration: none;

        &:hover {
          color: $default-color;
        }
      }
    }
  }

  &Header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &Title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.125;
      margin-left: 15px;
    }
  }

  &Footer {
    &Title {
      text-align: left;
      margin: 24px 0;
      color: #4a4a4a;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.25;
    }

    &ContentTable {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 14px;

      tbody tr th:nth-of-type(1) {
        max-width: 200px;
      }

      tbody tr td {
        text-align: right;
      }
    }
  }
}

.fa_check_circle__active {
  color: #7833f3 !important;
}

.CirkleIcon {
  i {
    height: 30px;
    width: 30px;
    color: #dcc9fc;

    &:hover {
      color: #7833f3;
    }
  }
}

@import '../../../sass/mixins';

.IssuerRequests {
  width: 100%;
  position: relative;
  padding: 48px 24px;

  &Content {
    align-items: flex-start !important;
    flex-direction: column;
    @include container;
  }

  &Page {
    width: 100%;
    display: flex;
    flex-direction: column;

    &Arrow,
    &ArrowRotate {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      margin-right: 10px;

      &Rotate {
        transform: rotate(-180deg);
      }
    }
  }

  &RfpContent {
    display: flex;
    flex-direction: column;
    padding: 34px;
    margin-bottom: 25px;
  }

  &OtherContent {
    display: flex;

    .SelectOptionWrapper {
      margin-right: 75px;
      div {
        margin-top: 0;
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 25px;
      }
    }
  }
}

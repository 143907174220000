@import '../../sass/variables';

.PageHeader {
  width: 100%;
  min-height: 87px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 26px 16px;
  margin-bottom: 25px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  cursor: default;

  &Title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-transform: capitalize;
  }

  &Wrapper {
    display: flex;
    align-items: center;
  }

  &Arrow,
  &Elipsis {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
  }

  &Arrow {
    position: absolute;
    left: -30px;
    margin-right: 10px;
    color: $hover-blue;
  }

  &DropArrow,
  &DropArrowActive {
    display: flex;
    align-items: center;
    margin-right: 10px;
    img {
      transition: all 0.4s;
    }
    cursor: pointer;
  }

  &DropArrowActive {
    img {
      transform: rotate(-180deg);
    }
  }
}

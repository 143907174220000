@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.InputV2 {
  height: 48px;
  padding: $indent14 $indent20;
  margin-top: $indent8;
  border-radius: 6px;
  border: 1px solid $color-gray-200;
  gap: 10px;
  outline: none;
  transition: all 0.3s;

  &::placeholder {
    @include h6;

    text-align: left !important;
    color: $color-gray;
  }

  &:focus {
    border: 1px solid $color-purple;
  }

  &Wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  &Label {
    @include h6;

    text-align: left !important;
    font-weight: 600 !important;
    color: $color-black-400;
  }
}

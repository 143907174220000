@import '../../sass/mixins';
@import '../../sass/variables';

.MembersTable {
  width: 100%;
  font-size: 14px;
  // overflow: auto;

  &Title {
    color: #4a4a4a;
    font-size: 18x;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    margin: 24px 0;
  }

  &Wrapper {
    overflow-y: auto;
  }

  &Icons {
    display: flex;
    align-items: center;
  }
}

.EditIcon {
  color: $light-blue;
  cursor: pointer;
  font-size: 16px;
}

.TrashIcon {
  color: $danger-red;
  cursor: pointer;
  font-size: 18px;
  margin-left: 14px;
  margin-top: 2px;
}

.Modal {
  padding: 7px 40px;

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 53px;
    text-transform: capitalize;
  }

  &Content {
    text-align: left;

    &Member {
      &Item {
        margin-top: 10px;
      }
    }
  }

  &Footer {
    display: flex;
    align-items: center;
    margin-top: 48px;
    button {
      width: 130px;
      margin-right: 10px;
    }
  }
}

.SelectedOfferCard {
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 37px auto;

  &Header {
    width: 95%;
    display: flex;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 18px;

    &Wrapper {
      display: flex;
      cursor: pointer;
    }

    &ImageWrapper {
      margin-right: 10px;
    }

    &Arrow,
    &ArrowActive {
      transition: all 0.5s;
    }

    &ArrowActive {
      transform: rotate(180deg);
    }
  }
}

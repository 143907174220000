@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Plans {
  @include main-adaptive;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $indent110;
  padding: 0 $indent80;
  user-select: none;

  @include mobile {
    margin-top: $indent68;
    padding: 0 $indent20;
  }

  &Swiper {
    display: none;

    @include tablet-1000 {
      display: block;
    }
  }

  &Header {
    @include h6;

    color: $color-purple-600;
  }

  &Title,
  &SubTitle {
    width: 100%;
    max-width: 892px;
  }

  &Title {
    @include h2;

    margin-top: $indent8;

    @include mobile {
      @include h3;
    }
  }

  &SubTitle {
    @include h5;

    margin-top: $indent24;

    @include mobile {
      @include h6;
      font-size: $indent16;
      line-height: 24px;
    }
  }

  &Wrapper {
    width: 100%;
    flex-direction: column;
    margin-top: $indent60;

    @include mobile {
      margin-top: $indent40;
    }
  }

  &ItemWrapper {
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-top: $indent24;
    }

    @media screen and (max-width: 1439px) {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    @include tablet-1000 {
      display: none;
    }
  }
}

.PlansItem {
  width: 100%;
  max-width: 296px;
  display: flex;
  align-items: center;
  padding: $indent20 $indent24;
  border-radius: $indent12;
  transition: all 0.3s;

  &:hover {
    background-color: $color-white;
    box-shadow: -30px 25px 40px -15px rgba(189, 16, 204, 0.3);
  }

  @include tablet-1000 {
    max-width: 260px;
  }

  &Icon {
    &Wrapper {
      height: 70px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $color-purple-100;
      margin-right: $indent20;
    }
  }

  &Title {
    @include h4;
  }
}

@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.About {
  @include main-adaptive;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $indent110;
  padding: 0 $indent80;
  user-select: none;

  @include tablet-1000 {
    flex-direction: column;
  }

  @include mobile {
    padding: 0 $indent20;
    margin-top: $indent68;
    margin-bottom: $indent68;
  }

  &Section {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 25px;
      @include tablet-1000 {
        margin-right: 0;
      }
    }
  }

  &Header {
    @include h6;

    color: $color-purple-600;
    text-align: left !important;
  }

  &Title,
  &SubTitle {
    width: 100%;
    max-width: 510px;
    text-align: left !important;

    @include tablet-1000 {
      max-width: unset;
    }
  }

  &Title {
    @include h2;

    margin-top: $indent8;

    @include mobile {
      @include h3;
    }
  }

  &SubTitle {
    @include h5;

    margin-top: $indent24;
  }

  &Content {
    display: flex;
    justify-content: space-between;
    margin-top: $indent50;
  }

  &Button {
    margin-top: $indent50;
  }

  &ImageSection {
    position: relative;

    @include mobile {
      margin-top: $indent40;
    }

    img {
      width: 100%;
      max-width: 642px;
      -webkit-user-drag: none;
    }

    &Content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: $indent32;

      @include mobile {
        padding: $indent16;
      }
    }
  }
}

.AboutSectionItem {
  &Title,
  &SubTitle {
    width: 100%;
    max-width: 136px;
    text-align: left !important;
  }

  &Title {
    @include h2;
  }

  &SubTitle {
    @include h5;
    margin-top: $indent8;
  }
}

.AboutImageItem {
  display: flex;
  align-items: center;
  padding: $indent12 $indent20;
  background-color: $color-white;
  border-radius: 50px;

  @include mobile {
    padding: 6px 10px;
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: $indent8;

    @include mobile {
      height: 10px;
      width: 10px;
    }
  }

  &Title {
    @include h6;
  }

  &:nth-child(2) {
    margin: $indent16 0;
  }
}

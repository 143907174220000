@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    color: $color-blue-600;
  }

  i {
    margin-right: 5px;
  }

  &Inverse {
    width: 100%;
    color: $color-red;
    background-color: $color-white;
    border: 1px solid $color-red;

    &:hover {
      color: $color-white;
      background-color: $color-red-600;
    }
  }

  &Primary {
    width: 100%;
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      color: $color-white;
      background-color: $color-blue-600;
    }
  }

  &PrimaryPurple {
    @include font18;

    background-color: $color-purple;
    color: $color-white;
    border-radius: 10px;

    &:hover {
      color: $color-white;
    }
  }

  &PrimaryBlack {
    @include font18;

    background-color: $color-black-600;
    color: $color-white;
    border-radius: 10px;

    &:hover {
      color: $color-white;
    }
  }

  &Secondary {
    background-color: $color-white;
    border: 1px solid $color-gray;

    &:hover {
      color: $color-black;
      border: 1px solid $color-gray-400;
    }
  }

  &Danger {
    color: $color-white;
    background-color: $color-red;

    &:hover {
      color: $color-white;
      background-color: $color-red-600;
    }
  }

  &Small,
  &Medium,
  &Large {
    font-family: 'Inter';
  }

  &Small {
    height: 33px;
  }

  &Medium {
    height: 48px;
    padding: $indent12 $indent24;
  }

  &Large {
    height: 56px;
    padding: $indent16 $indent24;
  }
}

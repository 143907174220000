@import '../../../sass/mixins';

.AgencyAccounts {
  width: 100%;
  align-items: flex-start !important;
  flex-direction: column;
  min-height: 600px;

  &TableWrapper {
    overflow-y: auto;
  }
}

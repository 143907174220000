@import '../../sass/variables';

.Layout {
  display: flex;
  flex: 1 1;
  height: 100vh;
  box-sizing: border-box;

  &Menu {
    width: 200px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: linear-gradient(180deg, #0d0023 0%, rgba(13, 0, 35, 0.93) 100%);
    padding: 28px;
    color: #c4c4c4;
    font-size: 14px;
    overflow: auto;

    &Logo {
      margin-bottom: 35px;
      position: relative;

      img {
        width: 100%;
      }
    }

    &Item,
    &ItemPro {
      padding: 3px;
      font-size: 9px;
      white-space: nowrap;
      text-align: center;
      background-color: #7b61ff;
      border-radius: 3px;

      cursor: pointer;
    }

    &ItemPro {
      position: absolute;
      top: -10px;
      right: -10px;
      // background-color: #f1ebff;
      // color: #7b61ff;

      cursor: default;
    }

    &Wrapper {
      margin-top: 29px;
    }

    &Admin {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 9px;
      padding: 2px 8px;
      background-color: $purple-primary-ligth;
      border-radius: 0 0 2px 2px;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 28px 35px;
    background-color: $default-gray;
    overflow-y: auto;
  }

  &RemovePadding {
    padding: 0;
  }

  &Logout {
    @media screen and (max-height: 900px) {
      padding: 20px 0;
    }

    margin-top: auto;
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      color: $white;
    }
  }

  &Impersonate {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #e9d814;
    padding: 2px 5px;
    border-radius: 0 0 2px 2px;
    color: $white;
    font-size: 12px;
    z-index: 100;
  }

  &Footer {
    @media screen and (min-height: 900px) {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    width: 100%;
    font-size: 10px;
    color: $white;
  }

  hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #c4c4c4;
    margin-bottom: 10px;
    margin-top: 5px;
  }
}

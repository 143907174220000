@import '../../../../sass/variables';

.CreateQuoteButton {
  margin-top: 30px;
  margin-bottom: 20px;

  button {
    width: 130px;
  }
}

.ProposalViewQuotesContent {
  margin-left: auto;
}

.ProposalViewQuotesButton {
  width: 130px;
  margin-top: 10px;
}

.Spacer {
  padding-bottom: 20px;
}

.ProposalViewQuotesWrapper {
  button {
    width: 180px;
  }
}

.VirginCoverage {
  padding: 0 50px;

  &Title {
    padding-top: 10px;
    margin-bottom: 20px;
    color: #4a4a4a;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
  }

  &FormWrapper {
    background-color: $white;
  }
}

@import '../../sass/variables';
@import '../../sass/mixins';

.AccountDetails {
  min-height: 275px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 65px 75px 25px 75px;
  background-color: $white;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 65px 20px 10px 20px;
  }

  &Wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 600px) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 10px;
    }
  }

  &Content {
    width: 100%;
    display: flex;
    flex-direction: column;

    &Info {
      width: 100%;
      display: flex;
      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      @media screen and (max-width: 600px) {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
      }
    }
  }

  &Status {
    margin-top: 10px;
  }

  &Arrow {
    position: absolute;
    top: 10px;
    left: -25px;
    margin-right: 10px;
    color: $hover-blue;
    font-size: 18px;
    cursor: pointer;
  }

  &EditContent {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-left: auto;

    button {
      width: 135px;
      margin-right: 21px;
    }

    i {
      font-size: 20px;
      color: #e62222;
      cursor: pointer;
    }
  }

  &EditButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &Image {
    position: relative;

    &Wrapper {
      width: 25%;
      display: flex;
      align-items: center;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-right: 15px;

      @media screen and (max-width: 800px) {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    div {
      margin-bottom: 0;
    }
  }

  &Edit {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    opacity: 0;

    &:hover {
      opacity: 1;
      background-color: #00000033;
      cursor: pointer;
    }

    // @media (hover: hover) {
    //   &:hover {
    //     opacity: 1;
    //     background-color: #00000033;
    //     cursor: pointer;
    //   }
    // }
  }

  &Icon {
    margin-left: 10px;
    font-size: 36px;
    color: $white;
  }

  &Column {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // margin-top: 28px;
    padding: 0 10px;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  &Item {
    &Wrapper {
      text-align: left;

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        margin-top: 15px;
      }

      @media screen and (max-width: 600px) {
        &:nth-of-type(1) {
          margin-top: 15px;
        }
      }
    }

    &Title {
      max-width: 280px;
      word-wrap: break-word;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
      text-align: left;
    }

    &SubTitle {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }

    &Description {
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      //   word-wrap: break-word;
      word-break: break-word;
      margin-top: 2px;
    }
  }
}

.LoaderWrapper {
  min-height: 180px;
  width: 100%;
}

@import '../../../sass/variables';

.DecisionSupport {
  margin-bottom: 15px;

  &Title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-transform: capitalize;
  }

  &Wrapper {
    margin-top: 10px;
    button {
      width: 185px;
    }
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $default-gray;
    padding: 30px;
    margin: 25px 0;

    &Title {
      display: flex;
      align-items: center;
      width: 300px;
      color: #4a4a4a;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      text-align: left;

      &Arrow,
      &ArrowActive {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-right: 10px;
        img {
          transition: all 0.4s;
        }
        cursor: pointer;
      }

      &ArrowActive {
        img {
          transform: rotate(-180deg);
        }
      }
    }

    &Buttons {
      margin-top: 10px;
      width: 300px;
      display: flex;

      button {
        height: 35px;
        padding: 8px 9px;
      }

      button:nth-of-type(1) {
        width: 130px;
      }

      button:nth-of-type(2) {
        width: 150px;
        margin-left: 17px;
      }
    }
  }

  &Content {
    background-color: $default-gray;
    padding: 34px;
  }
}

.FormContent {
  margin-left: 30px;
  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 25px;
    text-transform: capitalize;
  }
}

@import 'src/sass/common/';
@import 'src/sass/mixins/index';

.ProposalChat {
  height: 100%;
  display: flex;

  &Selected {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &Content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;
  }

  &Info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    min-height: 60px;
    border-bottom: 1px solid gray;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-top: 1px solid gray;

    &Input {
      max-height: 180px;
      height: 100%;
      width: 100%;
      resize: none;
      border: none;
      outline: none;
      border-radius: 10px;
      padding: 5px;
    }

    &Button {
      height: 30px;
      width: 30px;
      margin: 0 0 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #0b93f6;
      border: none;
      outline: none;
      cursor: pointer;
      margin-top: auto;
      transition: all 0.3s ease-in-out;

      &:disabled {
        opacity: 0.7;
      }

      i {
        color: $color-white;
        margin-right: 3px;
        font-size: 16px;
      }
    }
  }

  &Selected {
    padding: 10px;
    overflow-x: hidden;
  }
}

.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: auto;
  padding: 0;
  list-style: none;
}

.shared {
  position: relative; /* Setup a relative container for our psuedo elements */
  max-width: 400px;
  margin-bottom: 15px;
  padding: 10px 20px;
  line-height: 24px;
  word-wrap: break-word; /* Make sure the text wraps to multiple lines if long */
  border-radius: 25px;

  &:before {
    width: 20px;
  }

  &:after {
    width: 26px;
    background-color: #f5f5f5; /* All tails have the same bg cutout */
  }

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    height: 25px; /* height of our bubble "tail" - should match the border-radius above */
    content: '';
  }
}

.sent {
  align-self: flex-end;
  color: white;
  background: #0b93f6;

  &:before {
    right: -7px;
    background-color: #0b93f6;
    border-bottom-left-radius: 16px 14px;
  }

  &:after {
    right: -26px;
    border-bottom-left-radius: 10px;
  }
}

.received {
  align-self: flex-start;
  color: black;
  background: #e5e5ea;
  margin-left: 40px;

  &:before {
    left: -7px;
    background-color: #e5e5ea;
    border-bottom-right-radius: 16px 14px;
  }

  &:after {
    left: -26px;
    border-bottom-right-radius: 10px;
  }
}

.info {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 50%;
  background-color: #e5e5ea;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  cursor: pointer;
}

.noTail {
  margin-bottom: 2px;

  &:before,
  &:after {
    opacity: 0;
  }
}

.EmptyField {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;

  &Title {
    background-color: $color-gray-200;
    padding: 14px;
    border-radius: 10px;
  }
}

@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Layout {
  width: 100%;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_left.png');
    max-height: 950px;
    top: 0;
    left: 0;
    z-index: -2;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_left_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_right.png');
    max-height: 950px;
    top: 0;
    right: 0;
    z-index: -3;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_right_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../assets/pages/layoutMain/light_line.png');
    max-height: 700px;
    top: 0;
    left: 0;
    z-index: -1;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/light_line_mobile.png');
    }
  }
}

.Signup {
  @include container;

  display: flex;
  flex-direction: column;
  justify-content: center !important;
  user-select: none;
  -webkit-user-drag: none;

  @include mobile {
    margin: $indent20;
  }

  img {
    -webkit-user-drag: none;
  }

  &Card {
    width: 100%;
    max-width: 944px;
    min-width: 300px;
    border-radius: 24px;
    background-color: $color-white;
    padding: $indent40 $indent60;
    box-shadow: 0 0 30px 0 #c2c2c2;

    @include mobile {
      padding: $indent40 $indent20;
      border-radius: 16px;
    }

    &Footer {
      @include h6;
      color: $color-gray-400;

      span {
        color: $color-purple;
        font-weight: 600 !important;
        cursor: pointer;
      }
    }
  }

  &Logo {
    display: flex;
    justify-content: center;

    &Wrapper {
      display: flex;
      align-items: center;
      position: relative;
    }

    &Title {
      position: absolute;
      left: 100%;
      font-size: 18px;
      font-weight: 700;
      color: $color-purple-500;
    }

    img {
      cursor: pointer;
    }
  }

  &Form {
    &Wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: $indent16;

      @include mobile {
        flex-direction: column;
      }
    }

    &Section {
      width: 100%;
      max-width: 400px;

      &:first-child {
        margin-right: $indent24;

        @include mobile {
          margin-right: 0;
        }
      }

      // input {
      //   margin-bottom: $indent16;
      // }

      @include mobile {
        max-width: 100%;
      }
    }
  }

  form {
    button {
      width: 100%;
      font-size: $indent16 !important;
      margin-bottom: $indent24;
    }
  }

  &AgreeTerms {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: $indent16 0;

    &Checkbox {
      display: flex;
      align-items: center;
      position: relative;
    }

    label {
      @include h6;
      display: flex;
      align-items: center;

      color: $color-gray-400;
      word-break: break-word;

      img {
        position: absolute;
        top: 5px;
        left: 4px;
        z-index: 1;
        cursor: pointer;
      }

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
        appearance: none;
        background-color: #fff;
        margin: 0;
        border: 1px solid $color-purple;
        border-radius: 50px;
        margin-right: 10px;
        cursor: pointer;
      }

      input[type='checkbox']:checked {
        background-color: $color-purple;
      }

      a {
        text-decoration: none;
        color: $color-purple;
        margin-left: 5px;
        font-weight: 500 !important;
      }
    }
  }
}

@import 'src/sass/common/';
@import 'src/sass/mixins/index';

.Layout {
  width: 100%;
  position: relative;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_left.png');
    max-height: 950px;
    top: -426px;
    left: 0;
    z-index: -2;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_left_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_right.png');
    max-height: 950px;
    top: -426px;
    right: 0;
    z-index: -3;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_right_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../assets/pages/layoutMain/light_line.png');
    max-height: 700px;
    top: -426px;
    left: 0;
    z-index: -1;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/light_line_mobile.png');
    }
  }
}

.About {
  position: relative;
  background-color: $color-white;
  margin-top: 351px;
  display: flex;
  justify-content: center;

  &Image {
    width: 100%;
    height: 100%;
    max-width: 1280px;
    max-height: 506px;
    position: absolute;
    top: -300px;
    background: url('../../../assets/pages/about/about_logo.png') no-repeat;
    background-size: contain;
    background-position: center center;

    @include mobile {
      background: url('../../../assets/pages/about/about_logo_mobile.png') no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  &Content {
    @include container;
    min-width: 300px;
    flex-direction: row;
    padding-top: 50px;
    text-align: left;

    padding: 278px $indent80 $indent110 $indent80;

    @include laptop-mobile {
      padding: 278px $indent20 $indent68 $indent20;
    }

    @include mobile {
      flex-direction: column;
      padding: 278px $indent20 $indent68 $indent20;
    }
  }

  &Section {
    &Left,
    &Right {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &Left {
      // display: flex;
      margin-right: $indent24;

      @include mobile {
        width: 100%;
        margin-right: 0;
      }

      &Title {
        @include h2;
        text-align: left;
      }

      &SubTitle {
        @include h6;
        text-align: left;
        color: $color-purple;
        margin-bottom: $indent8;
      }
    }

    &Right {
      width: 100%;
      max-width: 856px;

      p {
        @include h5;

        text-align: left !important;
        margin-top: 0;
      }
    }
  }
}

@import '../../../../../sass/variables';

.ModalContent {
  width: 100%;
  padding: 0 25px;
  position: relative;

  &Header {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  &Contacts {
    &Header {
      display: flex;
    }
  }

  &Tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    div {
      margin-bottom: 0;
    }
  }

  &Search {
    max-width: 210px;
    display: flex;

    &Wrapper {
      position: relative;
    }

    &Clear {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%) rotate(45deg);
      cursor: pointer;
      font-size: 14px;
    }
  }

  &BrokerContacts {
    display: flex;
    flex-direction: column;
  }

  &CloneIcon {
    cursor: pointer;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    button {
      height: 35px;
      width: 130px;
    }
  }

  &Table {
    &CheckIcon {
      margin-top: 5px;
      cursor: pointer;

      &Disabled {
        cursor: pointer;
      }
    }
  }
}

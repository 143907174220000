@import '../../../../../sass/variables';

.Settings {
  width: 100%;
  display: flex;
  margin-top: 20px;
  text-align: left;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &ManageBilling {
    display: flex;
    flex-direction: column;
  }

  &Billing {
    display: flex;
    flex-direction: column;
    &Header {
      display: flex;
      align-items: center;

      &Status {
        margin-left: 15px;
      }
    }

    &Title {
      font-size: 18px;
      font-weight: 600;
      text-transform: capitalize;
    }

    &CardNumbers {
      margin: 0;
    }

    &Expire {
      margin-top: 5px;
    }

    th {
      vertical-align: top;
    }

    &Content {
      display: flex;
      padding: 24px;
      max-width: 600px;
      border: 2px solid #e0e0e0;
      border-radius: 8px;
      margin-top: 10px;

      &Seats {
        margin-bottom: 10px;

        &Wrapper {
          display: flex;
          align-items: center;
          font-size: 18px;

          button {
            width: 45px !important;
            font-size: 18px;
          }
        }

        &Title {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        &Counter {
          margin: 0 10px;
        }

        &Footer {
          display: flex;
          margin-top: 10px;
          button {
            width: 75px !important;
            margin-right: 10px;
          }
        }
      }

      &Item {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1;

        &TitleFree {
          font-size: 14px;
          font-style: italic;
          font-weight: 500;
          color: $monark-main-color;
        }

        &Title {
          font-size: 18px;
        }

        &SubTitle {
          font-size: 18px;
          font-weight: 500;
        }

        &Button {
          margin: 32px 0;
        }
      }

      &Table {
        th,
        td {
          padding: 8px;
        }
      }

      &Footer {
        button {
          width: 140px;
        }
      }
    }
  }

  &Table {
    border-spacing: 0;

    &Status {
      display: flex;
    }

    div {
      margin-bottom: 0;
    }

    td {
      width: auto;
    }
  }

  &Content {
    min-height: 480px;
    padding: 12px 24px;

    &Wrapper {
      margin-top: 20px;
    }
  }

  &Title {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.OrderModal {
  padding: 0 20px;
  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 14px;
  }

  &Content {
    margin-bottom: 14px;

    &Item {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  &Footer {
    display: flex;
    justify-content: flex-end;

    button {
      width: 130px;
      &:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
}

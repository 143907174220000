@import '../common';

@mixin h1 {
  color: $color-black;
  text-align: center;
  /* Heading/H1/Semibold */
  font-family: 'Inter';
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 133.333% */
}

@mixin h2 {
  color: $color-black;
  text-align: center;

  /* Heading/H2/Semibold */
  font-family: 'Inter';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
}

@mixin h3 {
  font-family: 'Inter';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 114.286% */
}

@mixin h4 {
  color: $color-black;

  /* Heading/H4/Medium */
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

@mixin h5 {
  color: $color-gray-500;
  text-align: center;

  /* Body/Large/Regular */
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

@mixin h6 {
  text-align: center;

  /* Body/Medium/Medium */
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

@mixin font18 {
  color: $color-white;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

@import '../../sass/variables';

.Modal {
  &Wrapper {
    padding: 0 20px;
  }

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 10px;
  }

  &Content {
    &Title {
      font-weight: 500;
      margin-bottom: 10px;
      text-align: left;
    }

    &GroupSize {
      display: flex;

      div {
        width: 95%;
      }
    }
    &Lines {
      margin-bottom: 10px;
      &Wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }

      &Item {
        &Image {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0;

          img {
            cursor: pointer;
          }
        }
      }
    }

    &Markets {
      margin-bottom: 10px;
      &Selected {
        display: flex;
        flex-wrap: wrap;

        &Item {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          margin: 0 10px 10px 0;
          background-color: $hover-blue;
          border-radius: 8px;
          font-size: 18px;
          font-weight: 600;
          color: white;

          &Icon {
            margin-left: 15px;
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  }

  &Footer {
    display: flex;
    justify-content: flex-end;

    button {
      width: 135px;
      margin-left: 10px;
    }
  }
}

@import '../../../sass/mixins';

.Privacy {
  padding: 48px 24px;

  &Content {
    text-align: left;
    flex-direction: column;
    font-family: Arial;
    font-size: 11pt;
    padding: 50px 0;
    line-height: 1.5;

    @include container;

    h3 {
      width: 100%;
      text-align: left;
      font-size: 32px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 24px;
      color: #363636;
    }

    div,
    p {
      text-align: left;
      width: 100%;

      &:nth-of-type(1) {
        margin-top: 0;
      }
    }
  }
}

.EffectiveDateWrapper {
  width: 100%;
  line-height: 1.542852;
  background-color: #ffffff;
  margin-top: 11pt;
  margin-bottom: 0pt;
  padding: 0pt 0pt 2pt 0pt;
}

.EffectiveDateTitle {
  font-size: 9pt;
  font-family: Arial;
  color: #293c4c;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.LastUpdateWrapper {
  width: 100%;
  line-height: 1.542852;
  background-color: #ffffff;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding: 9pt 0pt 2pt 0pt;
}

.LastUpdateTitle {
  font-size: 10pt;
  font-family: Arial;
  color: #293c4c;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.TextWrapper {
  line-height: 1.7142000000000002;
  background-color: #ffffff;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding: 0pt 0pt 11pt 0pt;
}

.TitleWrapper {
  font-size: 10.5pt;
  font-family: Arial;
  color: #293c4c;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.Link {
  text-decoration: none;
}

.LinkWrapper {
  font-size: 10.5pt;
  font-family: Arial;
  color: #48aed5;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: underline;
  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.ParagrapWrapper {
  width: 100%;
  line-height: 1.542852;
  background-color: #ffffff;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding: 1pt 0pt 2pt 0pt;
}

.ParagraphTitle {
  width: 100%;
  text-align: left;
  font-size: 11pt;
  font-family: Arial;
  color: #293c4c;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.NoMargin {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.ListWrapper {
  list-style-type: decimal;
  font-size: 10.5pt;
  font-family: Arial;
  color: #293c4c;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
}

.ListParagraph {
  line-height: 1.38;
  margin-top: 0pt;
  margin-bottom: 0pt;
}

.ListParagraphWrapper {
  line-height: 1.38;
  margin-top: 11pt;
  margin-bottom: 0pt;
}

.ListSpanTitle {
  font-size: 10.5pt;
  font-family: Arial;
  color: #293c4c;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: underline;
  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.Heading1 {
  width: 100%;
  line-height: 1.542852;
  background-color: #ffffff;
  margin-top: 0pt;
  margin-bottom: 2pt;
  padding: 1pt 0pt 0pt 0pt;
}

.ListParagraphCustom {
  line-height: 1.38;
  margin-top: 0pt;
  margin-bottom: 19pt;
}

.SpanLink {
  font-size: 10.5pt;
  font-family: Arial;
  color: #48aed5;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.HeadingCustom1 {
  width: 100%;
  line-height: 1.542852;
  background-color: #ffffff;
  margin-top: 12pt;
  margin-bottom: 2pt;
}

.ParagraphCustom1 {
  line-height: 1.7142000000000002;
  background-color: #ffffff;
  margin-top: 0pt;
  margin-bottom: 11pt;
}

.ParagraphCustom2 {
  line-height: 1.7142000000000002;
  background-color: #ffffff;
  margin-top: 0pt;
  margin-bottom: 0pt;
}

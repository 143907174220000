@import 'src/sass/common/';
@import 'src/sass/mixins/index';

.Layout {
  width: 100%;
  position: relative;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../../../assets/pages/layoutMain/blur_ellipse_left.png');
    max-height: 950px;
    top: -75px;
    left: 0;
    z-index: -2;

    @include mobile {
      content: url('../../../../../assets/pages/layoutMain/blur_ellipse_left_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../../../assets/pages/layoutMain/blur_ellipse_right.png');
    max-height: 950px;
    top: -75px;
    right: 0;
    z-index: -3;

    @include mobile {
      content: url('../../../../../assets/pages/layoutMain/blur_ellipse_right_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../../../assets/pages/layoutMain/light_line.png');
    max-height: 700px;
    top: -75px;
    left: 0;
    z-index: -1;

    @include mobile {
      content: url('../../../../../assets/pages/layoutMain/light_line_mobile.png');
    }
  }
}

.Main {
  @include main-adaptive;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  @include mobile {
    padding: 0 $indent20;
  }

  img {
    -webkit-user-drag: none;
  }

  &Title {
    @include h1;

    width: 100%;
    max-width: 978px;
    margin-top: $indent60;

    @include tablet-mobile {
      @include h2;

      margin-top: $indent24;
    }
  }

  &SubTitle {
    @include h5;

    width: 100%;
    max-width: 689px;
    margin-top: $indent24;
  }

  &Button {
    position: absolute;
    top: 7px;

    @include tablet-mobile {
      position: unset;
      margin-bottom: $indent60;
    }
  }

  &Content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin-top: auto;
    margin-top: $indent50;

    @include tablet-mobile {
      flex-direction: column;
      align-items: center;
    }

    &Image {
      width: 100%;
    }

    &TopRightImage,
    &LeftCenterImage,
    &LeftBottomImage {
      position: absolute;
    }

    &TopRightImage {
      top: -60px;
      right: 30px;
    }

    &LeftCenterImage {
      top: 190px;
      left: 55px;
      z-index: 1;
    }

    &LeftBottomImage {
      left: 135px;
      bottom: 40px;
    }
  }
}

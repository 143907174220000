@import '../../sass/variables';

.ExtraRows {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &Wrapper {
    margin-left: 10px;
  }

  div {
    margin-bottom: 0;
  }

  &Name {
    width: 50%;
    margin-right: 15px;
  }

  &Wrapper {
    width: 100%;
    display: flex;
  }

  &Title,
  &Value {
    width: 100%;
    margin-right: 10px;
    position: relative;
    margin-bottom: 14px !important;
  }

  &Title {
    max-width: 250px;
  }

  &MaxLength {
    position: absolute;
    top: -14px;
    right: 0;
    font-size: 14px;
  }

  &DeleteIcon {
    font-size: 20px;
    color: $default-red;
    cursor: pointer;
  }
}

@import '../../../sass/variables';

.GroupMembers {
  width: 100%;
  margin: 25px 0;

  &PageHeader {
    display: flex;
  }

  &Upload {
    button {
      width: 100px;
      margin-right: 17px;
    }
  }
}

.Modal {
  padding: 0 50px;

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 14px;
  }

  &Select {
    margin-bottom: 15px;
  }

  &Footer,
  &UploadFooter {
    margin-top: 14px;
  }

  &UploadFooter {
    display: flex;
    button {
      width: 100px;
      margin-right: 14px;
    }
  }
}

.UploadFileWrapper {
  height: 85px;
  width: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px 0;
  background-color: $default-gray;
  border: 1px solid $light-gray;
  border-radius: 4px;

  &:hover {
    background-color: #eeeeee;
  }

  cursor: pointer;
}

.UploadFile {
  display: flex;
  flex-direction: column;

  input {
    height: 100%;
    left: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
    cursor: pointer;
  }
}

@import '../../sass/variables';

.TabsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TabItem {
  display: flex;

  &Active {
    display: flex;
    justify-content: center;
    border: none;
    background-color: $monark-main-color;
    color: $white;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  &Disabled {
    display: flex;
    justify-content: center;
    background-color: $white;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  button {
    min-width: 125px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &Title {
    display: flex;
    position: relative;
  }

  button:first-child {
    border: 1px solid $monark-main-color;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
  }
  button {
    border: 1px solid $monark-main-color;
    border-right: none;
    border-left: 1px solid $monark-main-color;
    border-radius: 0;
  }
  button:last-child {
    border: 1px solid $monark-main-color;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: 1px solid $monark-main-color;
  }
}

.TabItemUnderline {
  display: flex;

  &Active {
    color: $monark-main-color;
    display: inline-block !important;
    border-bottom: 2px solid $monark-main-color !important;
    padding-bottom: 2px !important;
  }
  &Disabled {
    padding-bottom: 4px !important;
  }

  button:first-child {
    margin-right: 10px;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
  }

  button:last-child {
    margin-left: 10px;
  }
}

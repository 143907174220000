@import '../../../sass/variables';

.CreateAgency {
  width: 100%;
  display: flex;
  flex-direction: column;

  &Content {
    width: 100%;
    display: flex;
  }

  &Card {
    width: 100%;
    display: flex;
    background-color: $white;
    border-radius: 4px;
    padding: 26px 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &Content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &Title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }

    &Button {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        width: 136px;
      }
    }

    &AddressWrapper {
      width: 100%;
      display: flex;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      div:nth-of-type(1) {
        margin-right: 5px;
      }
      div:nth-of-type(2) {
        margin-right: 5px;
      }
    }
  }
}

@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Carriers {
  padding: $indent80;
  background-color: $color-gray-100;

  @media screen and (max-width: 1000px) {
    padding: $indent28 $indent20;

    &Content {
      img {
        height: 30px;
      }
    }
  }

  &Content {
    @include main-adaptive;

    display: flex;
    justify-content: space-between;
    user-select: none;

    img {
      -webkit-user-drag: none;
    }

    @include mobile {
      img {
        height: 15px;
      }
    }
  }
}

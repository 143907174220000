@import '../../../sass/mixins';

.ExecutiveSummary {
  table td,
  table th {
    text-transform: unset !important;
  }

  table td {
    text-transform: capitalize !important;
  }

  margin-bottom: 25px;

  &Total {
    font-weight: bold;
  }
}

@import 'src/sass/mixins';
@import 'src/sass/common';

.ManageAccount {
  &UploadImage {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 15px;
  }

  &Form {
    &Content {
      display: flex;
      flex-direction: column;
    }

    &Item {
      background-color: $color-white;
      margin-bottom: $indent12 !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  &Title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
  }

  &Content {
    min-height: 630px;
  }

  &Body {
    background-color: $color-white;
    margin: 24px 0;

    td {
      // span {
      //   margin: 0;
      //   display: flex;
      //   align-items: center;
      // }

      i {
        display: flex;
        align-items: center;
        margin-left: auto;
        color: $color-red-600;
        cursor: pointer;
      }
    }

    &Footer {
      display: flex;

      &Save {
        display: flex;
        margin-left: 10px;

        button {
          margin-left: 10px;
        }
      }
    }
  }

  &Address {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $indent12;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &Footer,
  &ContactsButton {
    display: flex;
    margin-top: 20px;

    button {
      margin-right: 10px;
    }
  }

  &ContactsButton {
    margin-top: 0;
  }
}

.LoaderWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

@import '../../../sass/variables';

.AccountView {
  &Content {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    @media screen and (max-width: 724px) {
      flex-direction: column;
    }
  }
}

.DeleteModalContent {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 25px;
    margin-right: 10px;
  }
}

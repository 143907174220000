@import '../../sass/variables';

.DropZoneWrapper {
  width: 212px;
}

.DropZoneDisabled {
  border: 1px solid #c4c4c4 !important;
}

.DropZone,
.DropZoneDisabled {
  border: 1px dashed $light-blue;
  border-radius: 5px;

  &Label {
    margin-left: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #888888;
  }

  &Content,
  &ContentSingle {
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 15px;

    &Item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      text-align: left;
      user-select: none;
      cursor: default;

      &Download,
      &Check {
        margin-right: 10px;
      }

      &Close {
        margin-left: 10px;
      }

      &Check {
        display: none;
      }
      &Download,
      &Close,
      &Check {
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &ContentSingle {
    border-radius: 5px;
  }

  &Area,
  &AreaGrey {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 5px;
    cursor: pointer;
  }

  &AreaGrey {
    border-radius: 0 0 5px 5px;
    background-color: #f5f5f5;
  }

  &Upload {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $light-blue;

    &Image {
      margin-right: 10px;
    }
  }

  &NotUploaded {
    color: #c4c4c4;
    font-weight: 400;
    font-size: 14px;
  }
}

.DropZoneContentItemWrapper {
  position: relative;

  .DropZoneContentItemCheck {
    position: absolute;
    top: 0;
    left: -2px;
    display: flex;
    background-color: #fff;
    animation: hideCheckIcon 1s forwards 2s;
  }

  .DropZoneContentItemDownload {
    display: flex;
    animation: showDownloadIcon 1s forwards 2s;
  }

  @keyframes hideCheckIcon {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes showDownloadIcon {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.ModalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    button:nth-of-type(1) {
      margin-right: 17px;
    }

    button {
      height: 35px;
      width: 130px;
    }
  }
}

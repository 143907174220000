@import '../../sass/variables';

.SearchSelect {
  width: 100%;
  position: relative;

  &Label {
    display: flex;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
  }

  &Required {
    color: red;
  }

  &Input {
    height: 33px;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    color: #363636;
    box-shadow: inset 0 1px 2px #0a0a0a1a;
    padding: 5px 12px;
    outline: none;

    &Wrapper {
      width: 100% !important;
      position: relative;

      div {
        margin-bottom: 0;
      }

      input:focus {
        border-radius: 4px 4px 0 0 !important;
      }
    }
  }

  &Clear {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 30px;
    z-index: 10;

    cursor: pointer;
  }

  &ArrowWrapper {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    transition: all 0.3s ease-in-out;
    transform-origin: center center;
  }

  &Arrow,
  &ArrowRotated {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  &ArrowRotated {
    transform: rotate(180deg);
  }

  &NotFind {
    padding: 5px;
  }

  &List {
    max-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    border: 1px solid $hover-blue;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    overflow-y: auto;
    z-index: 1;

    &Item {
      text-align: left;
      padding: 5px;
      cursor: pointer;
      &:hover {
        color: $white;
        background-color: $dark-blue;
      }
    }
  }
}

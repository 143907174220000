@import '../../../sass/mixins';

.AgencyProposals {
  width: 100%;
  align-items: flex-start !important;
  flex-direction: column;
  min-height: 600px;

  &Header {
    display: flex;
  }

  &Search {
    max-width: 210px;
    display: flex;
    align-items: center;
    margin-right: 15px;

    &Wrapper {
      position: relative;
    }

    &Clear {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%) rotate(45deg);
      cursor: pointer;
      font-size: 14px;
    }

    div {
      margin-bottom: 0;
    }

    input {
      height: 35px;
      font-size: 14px;
      border-radius: 4px 0 0 4px;
    }

    button {
      width: 45px;
      height: 35px;
      border-radius: 0 4px 4px 0;

      i {
        margin: 0;
      }
    }
  }

  &Filter {
    margin-left: auto;
    margin-right: 10px;

    &Wrapper {
      display: flex;
    }

    div {
      margin: 0;
    }
  }
}

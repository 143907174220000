@import '../../variables';

.Proposal {
  border-radius: 6px;
  background-color: white;
  color: #4a4a4a;
  margin-bottom: 5px;
  position: relative;
  border: 1px solid #dbdbdb;

  &Header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 45px;
    position: relative;
    user-select: none;

    cursor: pointer;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &BarIcon {
      margin-left: 10px;
      margin-right: 10px;
      color: #c4c4c4;
    }

    &Icon,
    &BarIcon,
    &IconActive {
      font-size: 20px;
      transition: all 0.4s ease-in-out;
      transform-origin: center center;
    }

    &IconActive {
      transform: rotate(180deg);
    }

    &Wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .ProposalHeaderItemHeading {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
      }

      .ProposalHeaderItemTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
    }

    &Item {
      width: 100%;
      max-width: 160px;
      display: flex;
      flex-direction: column;

      &Heading {
        text-align: center;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 5px;
        text-transform: uppercase;

        @media screen and (max-width: 1000px) {
          font-size: 9px;
        }
      }

      &Title {
        text-transform: capitalize;
        color: #363636;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        word-wrap: break-word;
      }
    }

    &Buttons {
      display: flex;
      margin-left: auto;

      @media screen and (max-width: 900px) {
        margin-left: unset;
      }

      button {
        border: unset;
        background-color: #f5f5f5;
        height: 35px;
        width: 130px;
        margin-right: 14px;
      }
    }

    &Status {
      display: flex;
      align-items: center;
      margin-right: 14px;

      div {
        width: 100%;
      }
    }
  }

  &Content {
    width: 95%;
    display: flex;
    align-items: center;
    background-color: #e5e5e5;
    padding: 20px 25px;
    margin: 0 auto 3px auto;

    &Body {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &Image {
      height: 20px;
      width: 20px;
      display: flex;
      margin-right: 10px;
      cursor: pointer;
    }

    &Item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 3px 10px;

      &Body {
        display: flex;
        margin-left: auto;
      }

      &Title,
      &CarrierTitle,
      &CarrierNameTitle {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
      }

      &SubTitle,
      &CarrierSubTitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        word-break: break-word;
      }

      &Status {
        display: flex;
        align-items: center;
        margin-right: 14px;
      }

      &Icon {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    &Buttons {
      display: flex;
      align-items: center;
      margin-left: auto;

      &Wrapper {
        display: flex;
        align-items: center;
        margin-left: auto;
      }

      &Resend {
        button {
          width: 147px !important;
        }
      }

      button {
        width: 130px;
      }
    }
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    margin-top: 30px;
  }

  &DeleteButton {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    z-index: 1;

    i {
      font-size: 20px;
      color: #e62222;
      cursor: pointer;
    }
  }

  &CheckIcon {
    color: $monark-main-color;
    cursor: pointer;
    margin-right: 10px;

    &Disabled {
      color: #bb99f9;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.ModalContent {
  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    button:nth-of-type(1) {
      margin-right: 17px;
    }

    button {
      height: 35px;
      width: 130px;
    }
  }
}

.VisibleContent {
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.HiddenContent {
  display: none;
}

.HeaderIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);

  &Wrapper {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    transform-origin: center center;
  }
}

.LoaderCard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoaderWrapper {
  height: 76px;
  width: 95%;
  background-color: #e5e5e5;

  div {
    height: 70px;
    width: 70px;
    border-top-color: white;
    border-right-color: white;
  }
}

.OfferModalContent {
  padding: 7px 76px;

  @media screen and (max-width: 520px) {
    padding: 7px 50px;
  }

  @media screen and (max-width: 340px) {
    padding: 7px 30px;
  }

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 53px;
    text-transform: capitalize;
  }
}

.ConfigModalContent {
  padding: 10px 25px;

  td,
  th,
  tr,
  thead {
    background-color: white !important;
  }
}

.OfferLoaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.EditIcon {
  height: 18px;
  width: 16px;
  color: $light-blue;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 14px;
}

@import '../../mixins';

.Offer {
  width: 100%;
  text-transform: capitalize;
  background-color: white !important;

  &Table {
    background-color: white !important;
    @include styled-table;

    th {
      min-width: 200px;
    }

    &Content {
      margin: 0 auto;
      overflow-x: auto;
    }

    &EmptyField {
      height: 25px;
    }

    &RateRow {
      cursor: pointer;
    }
  }

  &TierTable {
    width: 100%;
  }
}

.Button {
  button {
    width: 136px;
  }
}

.IssuerName {
  display: flex;
  align-items: center;
}

.StatusWrapper {
  margin-left: 10px;
}

.CustomFields {
  border-bottom: none !important;
}

.DropZone {
  display: flex;
  margin-top: 25px;
  text-transform: none;
  &Current {
    margin-right: 15px;
  }
}

.Arrow,
.ArrowActive {
  transition: all 0.5s ease-in-out;
}
.ArrowActive {
  transform: rotate(-180deg);
}

.AgeRatesWrapper {
  border-bottom: 1px solid #dbdbdb;

  td,
  th {
    background-color: #fff;
    border-bottom: unset;
    padding: 0 !important;
  }

  td {
    vertical-align: top;
    &:last-child {
      border-bottom: unset !important;
    }
  }

  span {
    td {
      padding: 8px 12px !important;
    }
  }
}

@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Layout {
  width: 100%;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_left.png');
    max-height: 700px;
    top: 0;
    left: 0;
    z-index: -2;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_left_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_right.png');
    max-height: 700px;
    top: 0;
    right: 0;
    z-index: -3;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_right_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../assets/pages/layoutMain/light_line.png');
    max-height: 700px;
    top: 0;
    left: 0;
    z-index: -1;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/light_line_mobile.png');
    }
  }
}

.Login {
  @include container;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobile {
    margin: $indent20;
  }

  &Card {
    width: 100%;
    max-width: 620px;
    min-width: 300px;
    border-radius: 24px;
    background-color: $color-white;
    padding: $indent40 $indent60;
    box-shadow: 0 0 30px 0 $color-box-shadow;

    @include mobile {
      padding: $indent40 $indent20;
      border-radius: 16px;
    }
  }

  &Logo {
    img {
      cursor: pointer;
    }
  }

  &ForgotPassword {
    @include h6;

    text-align: right !important;
    margin-top: $indent8;
    color: $color-purple;
    cursor: pointer;
  }

  form {
    margin-top: $indent24;

    button {
      width: 100%;
      margin-top: $indent16;
    }
  }

  &InputWrapper {
    margin-top: $indent16;
  }

  &Footer {
    @include h6;

    margin-top: $indent24;
    color: $color-gray;

    span {
      color: $color-purple;
      cursor: pointer;
    }
  }
}

.ErrorBox {
  padding-bottom: 20px;
  text-align: left;
  color: $color-red;
}

.Modal {
  &Content {
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr);

    &Item {
      margin: 10px 0;
      &Title {
        font-weight: 600;
      }
    }
  }
}

@import '../../../sass/variables';

.ProposalSetup {
  width: 100%;

  &Title {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    margin: 24px 0;
  }

  &Date {
    display: flex;

    &Item {
      width: 100%;
      max-width: 170px;
      margin-right: 20px;
    }
  }

  &TableWrapper {
    margin-top: 48px;
    button {
      width: 100px;
      margin-top: 24px;
    }
  }

  &Cancel {
    width: 210px;
    display: flex;
    justify-content: space-between;

    button {
      width: 100px;
      margin-top: 24px;
    }
  }

  &Navigate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    button {
      width: 152px;
    }
  }
}

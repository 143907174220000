@import '../../../sass/variables';

.ProposalCensus {
  width: 100%;

  &Title {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    margin: 24px 0;
  }

  &TableWrapper {
    margin-top: 48px;
    button {
      width: 100px;
      margin-top: 24px;
    }
  }

  &Cancel {
    width: 210px;
    display: flex;
    justify-content: space-between;

    button {
      width: 100px;
      margin-top: 24px;
    }
  }

  &Navigate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    button {
      width: 152px;
    }
  }
}

.UploadFileWrapper {
  height: 85px;
  width: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px 0;
  background-color: $default-gray;
  border: 1px solid $light-gray;
  border-radius: 4px;

  &:hover {
    background-color: #eeeeee;
  }

  cursor: pointer;
}

.UploadFile {
  display: flex;
  flex-direction: column;

  input {
    height: 100%;
    left: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
    cursor: pointer;
  }
}

.ModalContent {
  padding: 0 50px;

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 53px;
    text-transform: capitalize;
  }

  &Select {
    margin-bottom: 15px;
  }

  &Body {
    display: flex;
    flex-direction: column;
    min-height: 250px;
  }

  &Footer {
    display: flex;
    margin-top: 20px;

    button {
      width: 140px;

      &:nth-of-type(2) {
        margin-left: 14px;
      }
    }
  }
}

.ModalWrapper {
  :nth-of-type(1) {
    :nth-of-type(1) {
      background-color: red;
    }
  }
}

@import '../../../sass/variables';

.IssuerProposalHeader {
  &Buttons {
    display: flex;

    button:first-child {
      margin-right: 10px;
    }
  }

  &Export {
    display: flex;
    position: relative;

    &DropDown {
      width: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translate(-50%);
      padding-top: 10px;
      background-color: #e5e5e5;
      clip-path: polygon(100% 100%, 100% 10%, 55% 10%, 50% 0, 45% 10%, 0 10%, 0 100%);
      z-index: 1;

      &Link {
        color: $default-color;
        text-decoration: none;
      }

      &Item {
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-bottom: 11px;
        cursor: pointer;

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-top: 0;
        }

        &Title {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          padding-left: 14px;
        }
      }
    }

    &Csv {
      height: 35px;
      width: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      color: black;
      border: 1px solid #dbdbdb;
      text-decoration: none;
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 4px;
      margin-left: 17px;

      &:hover {
        color: $default-color;
        border: 1px solid $dark-gray;
      }
    }

    button {
      height: 35px;
      width: 130px;
    }
  }
  button {
    height: 35px;
    width: 130px;
  }
}

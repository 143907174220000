@import '../../../sass/mixins';

.IssuerMyRequests {
  width: 100%;
  position: relative;

  &Content {
    align-items: flex-start !important;
    flex-direction: column;
    @include container;
  }
}

@import '../../../../../sass/variables';

.PaymentMethod {
  width: 100%;
  display: flex;
  text-align: left;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &Table {
    border-spacing: 0;

    &Status {
      display: flex;
    }

    div {
      margin-bottom: 0;
    }

    td {
      width: auto;
    }
  }

  &Content {
    width: 100%;
  }

  &Title {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }

  &Billing {
    display: flex;
    &Expire {
      margin-left: 20px;
    }
  }

  &Update {
    width: 136px;
    margin-right: auto;
  }
}

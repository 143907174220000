@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.PhoneInput {
  width: 100%;
  height: 48px;
  padding: $indent14 $indent20;
  margin-top: $indent8;
  border-radius: 6px;
  border: 1px solid $color-gray-200;
  gap: 10px;
  position: relative;
  transition: all 0.3s;
  outline: none;

  &:focus {
    border: 1px solid $color-purple;
  }

  &::placeholder {
    @include h6;

    text-align: left !important;
    color: $color-gray;
  }

  &Wrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;
    text-align: left;
  }

  &Select {
    width: 100%;
    max-width: 112px;
    margin-right: $indent12;
  }
}

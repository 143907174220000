@import 'src/sass/mixins/index.scss';
@import 'src/sass/common';

.Wrapper {
  width: 100%;
  text-align: left;
  position: relative;
}

.Select {
  @include h6;

  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  position: relative;
  background-color: $color-white;
  margin-top: $indent8;
  padding: $indent14 $indent20;
  color: $color-black-400;
  border: 1px solid $color-gray-200;
  text-align: left;
  cursor: pointer;

  &Label {
    @include h6;

    text-align: left !important;
    font-weight: 600 !important;
    color: $color-black-400;
  }

  &Input {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    padding: $indent12 $indent20;
    border-radius: 6px;
    border: 0px;
    outline: none;
    margin: 0 !important;
  }

  &Menu {
    &Hidden {
      display: none;
    }

    &Active {
      width: 100%;
      max-height: 200px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 77px;
      left: 0;
      background-color: $color-white;
      padding: $indent14;
      border-radius: 6px;
      border: 1px solid $color-gray-200;
      z-index: 1;
      transition: all 0.3s;
    }

    &Item {
      display: flex;
      align-items: center;
      padding: $indent8;
      border-radius: 6px;
      transition: all 0.3s;
      cursor: pointer;

      img {
        margin-right: $indent8;
      }

      &:hover {
        background-color: $color-gray-200;
      }
    }
  }
}

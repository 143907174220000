@import '../../sass/variables';

.input-contain {
  display: flex;
  position: relative;
}

.Input {
  height: 33px;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 5px 13px;
  outline: none;
  transition: 0.4s;

  &:hover {
    border: 1px solid $dark-gray;
  }
}

.Input:focus {
  outline: none;
  border-color: $light-blue;
}

.Input:focus + .placeholder-text .text,
:not(.Input[value='']) + .placeholder-text .text {
  background-color: white;
  color: #a6a6a6;
  transform: translate(0, -110%);
  font-size: 12px;
  z-index: 1;
}

.Input:focus + .placeholder-text .text {
  border-color: $light-blue;
  color: $light-blue;
}

.Input:disabled {
  cursor: not-allowed;
}

.placeholder-text {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background-color: transparent;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.text {
  position: relative;
  padding: 0 4px;
  background-color: white;
  transform: translate(0);
  color: gray;
  transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out,
    color 0.15s ease-out;
  font-size: 14px;
}

.placeholder-text {
  padding: 0 10px;
}

@import '../../../sass/mixins';
@import '../../../sass/variables';

.Proposals {
  height: 100%;
  width: 100%;
  min-height: 600px;

  &UpdateBilling {
    margin-top: 24px;
    text-align: left;

    &Link {
      text-decoration: none;

      color: $hover-blue;

      &:hover {
        color: $default-color;
      }
    }
  }
}

.AccountTableWrapper {
  overflow-y: auto;
}

@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Layout {
  width: 100%;
  position: relative;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_left_ben.png');
    max-height: 950px;
    top: -75px;
    left: 0;
    z-index: -2;

    @include tablet-1000 {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_left_ben_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_right_ben.png');
    max-height: 950px;
    top: -75px;
    right: 0;
    z-index: -3;

    @include tablet-1000 {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_right_ben_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../assets/pages/layoutMain/light_line_ben.png');
    max-height: 700px;
    top: -75px;
    left: 0;
    z-index: -1;

    @include tablet-1000 {
      content: url('../../../assets/pages/layoutMain/light_line_ben_mobile.png');
    }
  }
}

.BenefitsQuoting {
  &Wrapper {
    @include container;

    flex-direction: column;
    padding: 0 $indent80 0 $indent80;

    @include laptop-mobile {
      padding: 0 $indent20;
    }
  }

  &Section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $indent110;

    &:first-child {
      margin-top: $indent60;
    }

    @include laptop-mobile {
      flex-direction: column;
      margin-bottom: $indent68;

      &:nth-child(2) {
        img {
          order: 2;
        }
      }
    }

    &Content {
      width: 100%;
      max-width: 540px;
      display: flex;
      flex-direction: column;

      @include laptop-mobile {
        margin-bottom: $indent40;
      }
    }

    &Image {
      width: 100%;
      max-width: 642px;
      box-shadow: 0 0 30px 0 $color-box-shadow;
      border-radius: 24px;
    }

    p {
      @include h5;

      text-align: left !important;
      margin-top: 0;
      margin-bottom: $indent24;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.Section {
  margin-bottom: $indent24;

  &Title {
    @include h2;
    text-align: left;
  }

  &SubTitle {
    @include h6;
    text-align: left;
    color: $color-purple;
    margin-bottom: $indent8;
    text-transform: uppercase;
  }

  &Item {
    @include h5;

    display: flex;
    text-align: left !important;
    margin-top: 0;
    margin-bottom: $indent24;

    img {
      width: 20px;
      margin-right: $indent12;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@import '../../sass/variables';

.UploadImage {
  height: 128px;
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;

  .UploadLabel {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    opacity: 1;

    &:hover {
      opacity: 1;
      background-color: #00000033;
      cursor: pointer;
    }
  }

  .UploadTitle {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 3.5px 12px;
    border-radius: 4px;
    background-color: $default-gray;
    border: 1px solid $light-gray;
    font-size: 14px;
    cursor: pointer;
    z-index: 1;

    i {
      margin-right: 5px;
    }
  }

  .UploadInput {
    opacity: 0;
  }

  .UploadButton {
    width: 100%;
    position: absolute;
    bottom: -33px;
    padding: 2px;

    background-color: $white;
    border-radius: 4px;
    border: 1px solid black;

    transition: 0.4s;
  }
}

.UploadMessage {
  margin-top: 20px;
}

.PreviewImage,
.PreviewImageCircle {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.PreviewImageCircle {
  border-radius: 50%;
}

.ModalContent {
  padding: 0 15px;

  &Cropper {
    height: 400px;
    width: 100%;
    position: relative;
    margin-top: 16px;
  }

  &Aspect {
    margin: 10px 0;
  }

  &Footer {
    display: flex;
    justify-content: center;

    button {
      height: 35px;
      width: 135px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

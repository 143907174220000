@import '../../sass/variables';

.SelectedRates {
  &Table {
    width: 100%;
    overflow: auto;

    table {
      height: auto;
      border-spacing: 0;
    }

    td,
    th {
      min-width: 250px;
      position: relative;
      text-align: left;
      padding: 7px 10px;
      border-bottom: 1px solid #dbdbdb;

      button {
        width: 150px;
      }
    }
  }

  &Logo {
    height: 25px;
  }
}

.PlanName {
  font-weight: 600;
}

.TableLink {
  text-align: left;
  margin: 24px 0;
  color: $hover-blue;
  cursor: pointer;

  a {
    color: $hover-blue;
    text-decoration: none;

    &:hover {
      color: $default-color;
    }
  }
}

.Icon,
.IconActive {
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.IconActive {
  transform: rotate(-180deg);
}

.VisibleTable {
  display: table-row;
}

.HiddenTable {
  display: none;
}

.BasePlan {
  margin-left: 20px;
  background-color: #e9d814;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 14px;
  color: $white;
  white-space: nowrap;
}

.SortIcons {
  display: flex;
  justify-content: flex-end;

  &Item {
    height: 35px;
    width: 35px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      transition: all 0.3s;
      background-color: #dbdbdb;
    }
  }
}

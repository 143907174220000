@import '../../../sass/mixins';
@import '../../../sass/variables';

.AdminLogin {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  margin-top: 76px !important;
  @include container;

  &Card {
    width: 100%;
    max-width: 550px;
    min-width: 300px;
    border-radius: 4px;
    background-color: #f3f3f4;
    padding: 25px 50px 75px 50px;
    box-shadow: 0 0 30px 0 #c2c2c2;
    position: relative;

    &Header {
      width: calc(100% - 16px);
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);

      &Title {
        padding: 2px 10px;
        background-color: $purple-primary-ligth;
        border-radius: 0 0 2px 2px;
        color: $white;
      }
    }
  }

  &Logo {
    margin-top: 14px;
    cursor: pointer;
  }

  form {
    button {
      margin-top: 25px;
    }
  }

  &Footer,
  &Copyright {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px 24px;

    button {
      padding-top: 5px;
      padding-bottom: 5px;
      color: $dark-blue;

      &:hover {
        color: $default-color;
      }
    }
  }

  &Copyright {
    justify-content: center;
    flex-direction: row;
    color: #a9a9a9;
    i {
      margin-right: 5px;
    }
  }
}

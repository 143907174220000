@import '../../../../../sass/variables';

.AgencyUsers {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &Header {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $default-gray;
    padding: 26px 30px;
    margin-bottom: 32px;

    &Title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }

    &Buttons {
      display: flex;

      button {
        height: 35px;
        width: 114px;
      }
    }
  }

  &Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &UsersWrapper,
    &PermissionsWrapper {
      padding: 15px 35px;
      background-color: $default-gray;
    }

    &UsersWrapper {
      margin-bottom: 25px;
    }
  }
}

.AddAgencyUsers {
  display: flex;

  &Wrapper {
    position: relative;
  }

  &Clear {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%) rotate(45deg);
    cursor: pointer;
    font-size: 14px;
  }

  div {
    margin-bottom: 0;
  }

  input {
    height: 35px;
    width: 290px;
    font-size: 14px;
    border-radius: 4px 0 0 4px;
  }

  button {
    height: 35px;
    width: 120px;
    display: flex;
    justify-content: space-between;
    border-radius: 0 4px 4px 0;

    i {
      margin: 0;
    }
  }
}

.AddCensusMember {
  button {
    width: 135px;
  }
}

.Modal {
  padding: 7px 85px;

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 53px;
    text-transform: capitalize;
  }

  &Content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;

    &TobaccoSelect {
      div {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  &Footer {
    display: flex;
    align-items: center;
    margin-top: 48px;
    button {
      width: 130px;
      margin-right: 10px;
    }
  }
}

@import '../../../../../sass/variables';

.UploadCensus {
  text-align: left;

  &Title {
    font-weight: 500;
    margin-top: 48px;
    margin-bottom: 10px;
  }

  &File {
    font-weight: bold;
    margin-right: 5px;
    text-decoration: none;
    color: $hover-blue;

    &:hover {
      color: $default-color;
    }
  }

  li {
    margin-top: 10px;
  }
}

code {
  background-color: whitesmoke;
  color: #ff3860;
  font-size: 0.875em;
  font-weight: normal;
  padding: 0.25em 0.5em 0.25em;
}

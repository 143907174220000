.InstantPetQuote {
  padding: 0 50px;

  &Title {
    padding-top: 10px;
    margin-bottom: 20px;
    color: #4a4a4a;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
  }

  &Form {
    &Row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 15px;
      align-items: center;
      margin-bottom: 15px;
    }

    &Item {
      display: flex;
      align-items: center;

      button {
        height: 40px;
        width: 100%;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &Table {
    button {
      margin-top: 15px;
      width: 140px;
    }
  }
}

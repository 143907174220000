@import '../../../../../sass/variables';

.Settings {
  width: 100%;
  display: flex;
  margin-top: 20px;
  text-align: left;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &ModalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
      margin-top: 30px;
    }
  }

  &Tabs {
    height: 140px;
    width: 250px;
    padding: 20px;
    border-radius: 5px;
    background-color: #f3f5f7;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &Title {
      margin-bottom: 12px;
      color: #7a7a7a;
      font-size: 14px;
      text-align: left;
      text-transform: uppercase;
    }

    button {
      width: 100%;
      justify-content: flex-start;
      text-align: left;
    }
  }

  &Billing {
    margin-top: 50px;

    &CardNumbers {
      margin: 0;
    }

    &Expire {
      margin-top: 5px;
    }

    th {
      vertical-align: top;
    }
  }

  &Table {
    border-spacing: 0;

    div {
      margin-bottom: 0;
    }

    td {
      width: auto;
    }
  }

  &Content {
    min-height: 480px;
    padding: 12px 24px;

    &Wrapper {
      margin-top: 20px;
    }

    &Image {
      width: 120px;
    }
  }

  &Title {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }

  &Status {
    display: flex;
  }
}

.ChangePassword {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: $hover-blue;

  &:hover {
    color: $default-color;
  }
}

.EditProfileButton,
.FooterButton {
  width: 160px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.FooterButton {
  button {
    width: 75px;
  }
}

.DangerZone {
  margin-top: 100px;

  &Title {
    font-size: 18px;
    margin-bottom: 24px;
  }
}

.CancelSubscription {
  display: flex;

  button {
    margin-right: 10px;
  }
}

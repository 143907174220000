@import '../../../sass/mixins';
@import '../../../sass/variables';

.NotAuthorized {
  display: flex;
  flex-direction: column;
  justify-content: center !important;

  @include container;

  &Card {
    width: 100%;
    max-width: 550px;
    min-width: 300px;
    border-radius: 4px;
    background-color: #f3f3f4;
    padding: 25px 50px 75px 50px;
    box-shadow: 0 0 30px 0 #c2c2c2;
  }

  &Content {
    &Title,
    &SubTitle,
    &Email {
      margin-top: 25px;
    }

    &Title {
      font-size: 18px;
      font-weight: 500;
    }

    &Email {
      margin-bottom: 25px;
      font-weight: 500;
    }
  }

  &Button {
    margin-top: 25px;
  }

  &Copyright {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px 24px;

    button {
      padding-top: 5px;
      padding-bottom: 5px;
      color: $dark-blue;

      &:hover {
        color: $default-color;
      }
    }
  }

  &Copyright {
    justify-content: center;
    flex-direction: row;
    color: #a9a9a9;
    i {
      margin-right: 5px;
    }
  }
}

.EmptyField {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 57px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.DashboardStatistic {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 71px;
  margin-bottom: 105px;

  &Item {
    padding: 0 20px;
    &Title {
      font-weight: 700;
      font-size: 64px;
    }

    &SubTitle {
      font-size: 14px;
    }
  }
}

.AgencyProfile {
  margin-left: 56px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }

  &UploadImage {
    width: 120px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &Name {
    margin-top: 24px;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
  }

  &Address {
    width: 100%;
  }

  &Description {
    max-width: 500px;
    display: flex;
    flex-direction: column;

    &Wrapper {
      margin-top: 10px;
      min-height: 40px;
      width: 100%;
      display: flex;
      border-top: 3px solid #e5e5e5;
    }

    &Item {
      display: flex;

      &Name {
        width: 100%;
        min-width: 140px;
        max-width: 140px;
      }

      &Value {
        width: 100%;
        word-break: break-all;
      }

      &Name,
      &Value {
        padding: 5px;
      }
    }
  }

  &ContentWrapper {
    width: 100%;
  }

  &Item {
    width: 100%;
    min-width: 140px;
    max-width: 140px;
  }

  &EditButton {
    display: flex;
    margin-top: 35px;
    button {
      width: 96px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.LoaderWrapper {
  margin-left: 56px;
}

@import 'src/sass/common/';
@import 'src/sass/mixins/index';

.Rooms {
  &Contacts {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &Wrapper {
    height: 100%;
    width: 100%;
    max-width: 350px;
    position: relative;
  }

  &Contacts {
    height: 100%;
    border-right: 1px solid $color-gray-300;
    max-width: 350px;
    user-select: none;
    position: relative;

    &ComingSoon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
    }

    &Header {
      display: flex;
      padding: $indent10 $indent14;
      top: 0;
      position: sticky;
      z-index: 1;
      background: #f5f5f5;

      &Input {
        width: 100%;
        margin-right: $indent12 !important;
      }
    }

    &Item {
      display: flex;
      align-items: center;
      padding: $indent10 $indent14;
      width: 100%;
      position: relative;
      cursor: pointer;

      &Selected {
        background-color: $color-gray-200;
      }

      &Badge {
        top: 5px;
        right: 10px;
        position: absolute;
      }

      &Image {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        border-radius: 100%;
        font-size: 22px;
        margin-right: $indent12;
        // color: $color-white;
      }

      &Content {
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      &Header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $indent4;
      }

      &Message {
        max-width: 200px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &Border {
        height: 1px;
        width: calc(100% - 28px);
        position: absolute;
        background-color: $color-gray-300;
        right: 14px;
        bottom: 0;
      }
    }
  }

  &Create {
    width: calc(100% - 550px);
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 15px;
    min-height: 60px;
    border-bottom: 1px solid gray;
    background-color: #f5f5f5;
    z-index: 1;
  }
}

.QuotesCard,
.QuotesCardPicked {
  border-radius: 6px;
  background-color: white;
  color: #4a4a4a;
  margin-bottom: 24px;
  border: 1px solid #dbdbdb;
  position: relative;

  &Header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 45px;
    position: relative;
    user-select: none;

    cursor: pointer;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &Icon,
    &IconActive {
      font-size: 20px;
      transition: all 0.1s ease-in-out;
      transform-origin: center center;
    }

    &IconActive {
      transform: rotate(180deg);
    }

    &Wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      .QuotesCardHeaderItem {
        width: 160px;
      }

      .QuotesCardHeaderItemHeading {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
      }

      .QuotesCardHeaderItemTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      @media screen and (max-width: 900px) {
        flex-direction: column;

        .QuotesCardHeaderItem {
          min-width: 160px;
        }
      }
    }

    &Name {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: auto;
    }

    &Item {
      width: 400px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1407px) {
        width: 250px;
      }

      @media screen and (max-width: 1000px) {
        width: 180px;
      }

      &Heading {
        text-align: center;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 5px;
        text-transform: uppercase;

        @media screen and (max-width: 1000px) {
          font-size: 9px;
        }
      }

      &Title {
        text-transform: capitalize;
        color: #363636;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        word-wrap: break-word;
      }

      &Status {
        margin-right: 15px;
      }
    }
  }

  &DeleteButton {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    z-index: 1;

    i {
      font-size: 20px;
      color: #e62222;
      cursor: pointer;
    }
  }

  &Buttons {
    display: flex;
    margin-left: auto;

    @media screen and (max-width: 900px) {
      margin-left: unset;
    }

    button {
      border: unset;
      background-color: #f5f5f5;
      height: 35px;
      width: 130px;
      margin-right: 14px;
    }
  }

  &EditButton {
    margin-right: 15px;
    button {
      width: 80px;
    }
  }

  &Footer {
    display: flex;
    margin-top: 30px;

    &ReadOnly {
      display: flex;
      margin-right: auto;

      button {
        width: 120px;
        margin-right: 10px;
      }
    }
  }
}

.QuotesCardPicked {
  border: 2px solid #2ecf00;
  transition: all 3s ease-in-out;
}

.HiddenQuote {
  display: none;
}

.VisibleQuote {
  display: block;
  margin-top: 30px;
  padding: 30px;
}

.HeaderBarIcon {
  margin-left: 10px;
  margin-right: 10px;
  color: #c4c4c4;
  font-size: 20px;
}

.HeaderIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);

  &Wrapper {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    transform-origin: center center;
  }
}

@import '../../../sass/variables';

.ConfigurationQuoteCard,
.ConfigurationQuoteCardApproved {
  border-radius: 6px;
  background-color: white;
  color: #4a4a4a;
  margin-bottom: 5px;
  position: relative;
  border: 1px solid #dbdbdb;

  &Header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 45px;
    position: relative;
    user-select: none;

    cursor: pointer;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &BarIcon {
      margin-left: 10px;
      margin-right: 10px;
      color: #c4c4c4;
    }

    &Icon,
    &BarIcon,
    &IconActive {
      font-size: 20px;
      transition: all 0.1s ease-in-out;
      transform-origin: center center;
    }

    &IconActive {
      transform: rotate(180deg);
    }

    &Individual {
      width: 100%;
      max-width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: auto;

      &Title {
        font-size: 18px;
        font-weight: 500;
      }
    }

    &Wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      .ConfigurationQuoteCardHeaderItem {
        width: 160px;
      }

      .ConfigurationQuoteCardHeaderItemHeading {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
      }

      .ConfigurationQuoteCardHeaderItemTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      @media screen and (max-width: 900px) {
        flex-direction: column;

        .ConfigurationQuoteCardHeaderItem {
          min-width: 160px;
        }
      }
    }

    &Item {
      width: 400px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1407px) {
        width: 250px;
      }

      @media screen and (max-width: 1000px) {
        width: 180px;
      }

      &Heading {
        text-align: center;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 5px;
        text-transform: uppercase;

        @media screen and (max-width: 1000px) {
          font-size: 9px;
        }
      }

      &Title {
        text-transform: capitalize;
        color: #363636;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        word-wrap: break-word;
      }
      &Status {
        margin-right: 15px;
      }
    }

    &Buttons {
      display: flex;
      margin-left: auto;

      @media screen and (max-width: 900px) {
        margin-left: unset;
      }

      button {
        border: unset;
        background-color: #f5f5f5;
        height: 35px;
        width: 130px;
        margin-right: 14px;
      }
    }
  }

  &Content {
    height: 76px;
    width: 95%;
    display: flex;
    align-items: center;
    background-color: #e5e5e5;
    padding: 20px 25px;
    margin-bottom: 3px;

    &Body {
      width: 100%;
      display: flex;
    }

    &Item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 30px;

      &Title {
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
      }

      &SubTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }

    &Buttons {
      display: flex;

      button {
        width: 130px;
        margin-right: 14px;
      }
      button:last-child {
        margin-right: 0;
      }
    }
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

    &ReadOnly {
      display: flex;
      margin-right: auto;
      margin-left: 30px;

      button {
        width: 120px;
        margin-right: 30px;
      }
    }

    button {
      width: 120px;
      margin-right: 30px;
    }
  }

  &DeleteButton {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    z-index: 1;

    i {
      font-size: 20px;
      color: #e62222;
      cursor: pointer;
    }
  }

  &CheckIcon {
    color: $monark-main-color;
    cursor: pointer;
    margin-right: 10px;

    &Disabled {
      color: #bb99f9;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.ModalContent {
  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    button:nth-of-type(1) {
      margin-right: 17px;
    }

    button {
      height: 35px;
      width: 130px;
    }
  }
}

.VisibleContent {
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.HiddenContent {
  display: none;
}

.HeaderIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);

  &Wrapper {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    transform-origin: center center;
  }
}

.LoaderCard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoaderWrapper {
  height: 76px;
  width: 95%;
  background-color: #e5e5e5;

  div {
    height: 70px;
    width: 70px;
    border-top-color: white;
    border-right-color: white;
  }
}

.Wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OfferModalContent {
  padding: 7px 76px;

  @media screen and (max-width: 520px) {
    padding: 7px 50px;
  }

  @media screen and (max-width: 340px) {
    padding: 7px 30px;
  }

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 53px;
    text-transform: capitalize;
  }
}

.ConfigurationQuoteCardApproved {
  border: 1px solid #2ecf00;
}

@import '../../sass/variables';

.AccountContacts {
  min-height: 332px;

  &Body {
    margin: 15px 0;
    &Table {
      &Edit {
        display: flex;

        button {
          height: 30px;
          width: 70px;
          margin-right: 10px;
        }

        i {
          display: flex;
          align-items: center;
          margin-left: auto;
          color: $danger-red;
          cursor: pointer;
        }
      }

      &Delete {
        display: flex;
        align-items: center;
      }
    }
    &Footer {
      display: flex;

      &Save {
        display: flex;
        margin-left: 10px;

        button {
          margin-left: 10px;

          width: 90px !important;
        }
      }

      button {
        width: 160px;
      }
    }
  }

  &Table {
    div {
      margin-bottom: 0 !important;
    }
  }
}

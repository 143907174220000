@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Layout {
  width: 100%;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_left.png');
    max-height: 950px;
    top: -75px;
    left: 0;
    z-index: -2;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_left_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_right.png');
    max-height: 950px;
    top: -75px;
    right: 0;
    z-index: -3;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_right_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../assets/pages/layoutMain/light_line.png');
    max-height: 700px;
    top: -75px;
    left: 0;
    z-index: -1;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/light_line_mobile.png');
    }
  }
}

.Terms {
  margin-top: $indent60;
  margin-bottom: $indent110;
  padding: $indent20;

  &Content {
    @include container;

    text-align: left;
    flex-direction: column;
    font-family: Arial;
    font-size: 11pt;
    line-height: 1.5;
    padding: $indent40 $indent60;
    background-color: $color-white;
    border-radius: 24px;
    box-shadow: 0 0 30px 0 #c2c2c2;

    @include mobile {
      padding: $indent40 $indent20;
      border-radius: 16px;
    }

    h3 {
      width: 100%;
      text-align: left;
      font-size: 32px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 24px;
      color: #363636;
    }

    div,
    p {
      margin: 16px 0;

      &:nth-of-type(1) {
        margin-top: 0;
      }
    }

    div {
      margin-bottom: 0;
    }
  }
}

.Ribbon {
  height: 43px;
  width: 105px;
  inset: 10px -12px auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 12px calc(10px + 15px);
  position: absolute;
  clip-path: polygon(
    25% 0%,
    100% 0%,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    calc(100% - 10px) calc(100% - 10px),
    25% calc(100% - 10px),
    15px calc(50% - 10px / 2)
  );
  background: #2ecf00;
  box-shadow: 0 -10px 0 inset #0005;
  color: white;
}

.input-wrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 10px;
}

.input-wrapper label {
  font-weight: 500;
  margin-bottom: 10px;
  white-space: nowrap;
}

.required-start {
  color: red;
}

.range-input {
  height: 33px;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 5px 9px;
  outline: none;
}

.range-input {
  padding: 0;
  box-shadow: unset;
}

.input-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.range-value {
  height: 24px;
  width: 50px;
  margin-left: 12px;
  padding: 5px;
  border-radius: 3px;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border: none;
  background-color: #e5e5e5;
  text-align: center;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
.range-value::-webkit-outer-spin-button,
.range-value::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.range-value[type='number'] {
  -moz-appearance: textfield;
}

input[type='range'].styled-slider {
  width: 100%;
  height: 20px;
  -webkit-appearance: inherit;
}

/*progress support*/
input[type='range'].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 8px + var(--ratio) * (100% - 8px));
}

input[type='range'].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  border-radius: 1em;
  background: #1496e9;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(max((4px - 1px - 1px) * 0.5, 0px) - 8px * 0.5);
}

input[type='range'].styled-slider::-webkit-slider-runnable-track {
  height: 4px;
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}

input[type='range'].styled-slider::-webkit-slider-thumb:hover {
  background: #1496e9;
}

input[type='range'].styled-slider:hover::-webkit-slider-runnable-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

input[type='range'].styled-slider::-webkit-slider-thumb:active {
  background: #2f98f9;
}

input[type='range'].styled-slider:active::-webkit-slider-runnable-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

input[type='range'].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#1496e9, #1496e9) 0 / var(--sx) 100% no-repeat, #efefef;
}

input[type='range'].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#1496e9, #1496e9) 0 / var(--sx) 100% no-repeat, #e5e5e5;
}

input[type='range'].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(#2f98f9, #2f98f9) 0 / var(--sx) 100% no-repeat, #f5f5f5;
}

/*mozilla*/
input[type='range'].styled-slider::-moz-range-thumb {
  width: 8px;
  height: 8px;
  border-radius: 1em;
  background: #1496e9;
  border: none;
  box-shadow: 0 0 2px black;
}

input[type='range'].styled-slider::-moz-range-track {
  height: max(calc(4px - 1px - 1px), 0px);
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}

input[type='range'].styled-slider::-moz-range-thumb:hover {
  background: #1496e9;
}

input[type='range'].styled-slider:hover::-moz-range-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

input[type='range'].styled-slider::-moz-range-thumb:active {
  background: #2f98f9;
}

input[type='range'].styled-slider:active::-moz-range-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

input[type='range'].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#1496e9, #1496e9) 0 / var(--sx) 100% no-repeat, #efefef;
}

input[type='range'].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(#1496e9, #1496e9) 0 / var(--sx) 100% no-repeat, #e5e5e5;
}

input[type='range'].styled-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient(#2f98f9, #2f98f9) 0 / var(--sx) 100% no-repeat, #f5f5f5;
}

/*ms*/
input[type='range'].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type='range'].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type='range'].styled-slider::-ms-thumb {
  width: 8px;
  height: 8px;
  border-radius: 1em;
  background: #1496e9;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type='range'].styled-slider::-ms-track {
  height: 4px;
  border-radius: 0.5em;
  background: #efefef;
  border: 1px solid #b2b2b2;
  box-shadow: none;
  box-sizing: border-box;
}

input[type='range'].styled-slider::-ms-thumb:hover {
  background: #1496e9;
}

input[type='range'].styled-slider:hover::-ms-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

input[type='range'].styled-slider::-ms-thumb:active {
  background: #2f98f9;
}

input[type='range'].styled-slider:active::-ms-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

input[type='range'].styled-slider.slider-progress::-ms-fill-lower {
  height: max(calc(4px - 1px - 1px), 0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: #1496e9;
  border: 1px solid #b2b2b2;
  border-right-width: 0;
}

input[type='range'].styled-slider.slider-progress:hover::-ms-fill-lower {
  background: #1496e9;
  border-color: #9a9a9a;
}

input[type='range'].styled-slider.slider-progress:active::-ms-fill-lower {
  background: #2f98f9;
  border-color: #c1c1c1;
}

input:disabled {
  cursor: not-allowed;
}

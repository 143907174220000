@import '../../../../../sass/variables';

.Settings {
  width: 100%;
  display: flex;
  margin-top: 20px;
  text-align: left;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &ModalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
      margin-top: 30px;
    }
  }

  &Billing {
    margin-top: 50px;

    &CardNumbers {
      margin: 0;
    }

    &Expire {
      margin-top: 5px;
    }

    th {
      vertical-align: top;
    }
  }

  &Table {
    border-spacing: 0;

    &Status {
      display: flex;
    }

    div {
      margin-bottom: 0;
    }

    td {
      width: auto;
    }
  }

  &Content {
    min-height: 480px;
    padding: 12px 24px;

    &Wrapper {
      margin-top: 20px;
    }
  }

  &Title {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.FooterButton {
  width: 160px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.FooterButton {
  button {
    width: 75px;
  }
}

.DangerZone {
  margin-top: 100px;

  &Title {
    font-size: 18px;
    margin-bottom: 24px;
  }
}

.CancelSubscription {
  display: flex;

  button {
    margin-right: 10px;
  }
}

.TableContent {
  overflow: auto;
  overflow-y: hidden;
}

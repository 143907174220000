@import '../../sass/mixins/';
@import '../../sass/common/';

.Header {
  width: 100%;
  position: absolute;
  top: 24px;

  @include mobile {
    top: 16px;
  }

  &Wrapper {
    @include main-adaptive;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $indent80;
    position: relative;

    @include mobile {
      padding: 0 $indent20;
    }
  }

  &Logo {
    display: flex;
    cursor: pointer;

    button {
      color: $color-blue-400;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-left: 30px;
    }
  }

  &Title {
    font-family: 'Inter';
    color: black;
  }

  &Buttons {
    display: flex;
    align-items: center;

    @include tablet-1000 {
      display: none;
    }
  }

  &EmailVerification {
    margin-left: auto;
  }

  &BurgerButton {
    display: none;

    @include tablet-1000 {
      display: block;
    }
  }

  &Menu {
    display: none;
    position: absolute;
    right: 0;
    top: 60px;
    left: 0;
    margin: 0 $indent80;
    padding: $indent12;
    background-color: $color-white;
    border-radius: 14px;
    box-shadow: 0 0 30px 0 $color-box-shadow;
    z-index: 1;

    @include tablet-1000 {
      display: block;
    }

    &Item {
      display: flex;
      justify-content: center;

      button {
        width: 100%;
      }
    }

    @include mobile {
      margin: 0 $indent20;
    }
  }
}

.ButtonWrapper {
  max-width: 200px;
  @include tablet-1000 {
    display: none;
  }
}

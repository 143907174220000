@import '../../../../sass/variables';

.Menu {
  &Item,
  &ItemActive,
  &ItemDisabled {
    display: flex;
    height: 17px;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: #c4c4c4;
    transition: all 0.5s ease-in-out;

    &:hover {
      color: $white;
    }

    &Loader {
      margin-left: 5px;
    }

    &Title {
      display: flex;
      align-items: center;
      position: relative;
    }

    &SubTitle {
      margin-left: 5px;
      padding: 3px;
      font-size: 9px;
      white-space: nowrap;
      background-color: $purple-primary-ligth;
      border-radius: 3px;
    }

    &Status {
      height: 4px;
      width: 4px;
      background-color: #c4c4c4;
      border-radius: 100%;
      margin-top: 3px;
      margin-left: 5px;
    }

    &Warning {
      margin-left: 5px;
      color: #ffc801;
    }
  }
  &ItemDisabled {
    cursor: not-allowed;

    &:hover {
      color: unset;
    }
  }

  &ItemActive {
    color: $white;
  }

  &ItemSelected {
    height: 17px;
    width: 6px;
    position: absolute;
    top: 0;
    left: -28px;
    background-color: $white;
    border-radius: 0 2px 2px 0;
    transition: all 0.5s ease-in-out;
  }

  &ItemSelectedHidden {
    visibility: hidden;
    transition: all 0.5s ease-in-out;
  }
}

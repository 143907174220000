@import '../../sass/variables';

.PhoneInput {
  &Wrapper {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 10px;

    label {
      font-weight: 500;
      margin-bottom: 10px;
      white-space: nowrap;
    }
  }

  &Content {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &Number {
    width: 100% !important;
    transition: 0.4s;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);

    &:focus {
      border: 1px solid $light-blue !important;
    }
  }
}

.RequiredStart {
  color: red;
}

.Tooltip {
  margin-left: 5px;
}

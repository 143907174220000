$white: #fff;

$light-blue: #209cee;
$hover-blue: #1496e9;
$dark-blue: #3273dc;

$default-gray: #f5f5f5;
$light-gray: #dbdbdb;
$dark-gray: #868686;

$default-red: #e62222;
$danger-red: #ff0000;
$hover-red: #ff2b56;

$default-color: #2c3e50;

$monark-main-color: #7833f3; // purple-primary
$purple-primary-ligth: #7b61ff;

@import '../../../../sass/variables';

.AgencyMenu {
  display: flex;
  flex-direction: column;
  text-align: left;

  &Line {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
  }
}

@import '../../sass/mixins/';

.LayoutMain {
  width: 100%;
  position: relative;

  &Hidden {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;

    @include mobile {
      height: unset;
      overflow: auto;
    }
  }

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    left: 0;
    z-index: -1;
  }

  &EllipseRight {
    right: 0;
    z-index: -2;
  }

  &LightLine {
    left: 0;
    top: 0;
  }

  &Wrapper,
  &WrapperHidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0 auto;
  }

  &WrapperHidden {
    height: 100%;
    width: 100%;

    // @include mobile {
    //   margin-top: 120px;
    // }
  }

  &Content {
    margin-top: 75px;
  }

  &ContentHidden {
    margin-top: 0;

    @include mobile {
      margin: $indent80 0;
    }
  }

  &Footer {
    @include h5;

    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    padding: 0 $indent60 $indent32;

    @include mobile {
      flex-direction: column;
      position: unset;
    }

    &Copyright {
      color: $color-gray-400 !important;

      @include mobile {
        margin-bottom: $indent8;
      }
    }

    &Cookies {
      color: $color-purple !important;
      font-weight: 700 !important;
      cursor: pointer;
    }
  }
}

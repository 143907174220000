@import 'src/sass/variables';

.Profile {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c4c4c4;
  border-radius: 11px;
  user-select: none;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  perspective: 1000px;
  z-index: 1;
  transition: all 0.1s ease-in-out;

  &Wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover .ProfileMenu {
    display: flex;
  }

  &Menu {
    width: auto;
    max-width: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 15px;
    border: 1px solid #c4c4c4;
    border-radius: 11px;
    background: linear-gradient(180deg, #0d0023 0%, rgba(13, 0, 35, 0.93) 100%);
    z-index: -1;

    &Item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      font-size: 14px;
      color: $white;

      &Name {
        width: 100%;
        margin-left: 10px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    :last-child {
      margin-bottom: 0;
    }

    &Animation {
      animation: growOut 100ms ease-in-out;
      transform-origin: top center;
    }
  }

  &Logo {
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $white;
  }

  &Name {
    width: 60%;
    font-size: 14px;
    color: $white;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Chevron {
    margin-right: 13px;
    transition: all 0.3s ease-in-out;

    &Active {
      rotate: -180deg;
      margin-right: 13px;
      transition: all 0.3s ease-in-out;
    }
  }

  &Menu {
    display: none;
    transition: all 0.3s eas;
  }

  &MenuActive {
    display: flex;

    transition: all 0.3s eas;
  }
}

@keyframes growOut {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.ActiveProfile {
  display: flex;
  flex-direction: column;
}

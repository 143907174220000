@import '../../sass/variables';

.Select {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  select {
    height: 33px;
    // width: 200px;
    font-size: 18px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    color: #363636;
    outline: none;
    cursor: pointer;

    &:hover {
      border: 1px solid $dark-gray;
    }
  }

  label {
    display: flex;
    font-weight: 500;
    margin-bottom: 10px;

    span {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  &Required {
    color: red;
  }
}

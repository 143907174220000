@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Layout {
  width: 100%;
  position: relative;

  &EllipseLeft,
  &EllipseRight,
  &LightLine {
    width: 100%;
    position: absolute;
  }

  &EllipseLeft {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_left.png');
    max-height: 950px;
    top: -75px;
    left: 0;
    z-index: -2;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_left_mobile.png');
    }
  }

  &EllipseRight {
    content: url('../../../assets/pages/layoutMain/blur_ellipse_right.png');
    max-height: 950px;
    top: -75px;
    right: 0;
    z-index: -3;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/blur_ellipse_right_mobile.png');
    }
  }

  &LightLine {
    content: url('../../../assets/pages/layoutMain/light_line.png');
    max-height: 700px;
    top: -75px;
    left: 0;
    z-index: -1;

    @include mobile {
      content: url('../../../assets/pages/layoutMain/light_line_mobile.png');
    }
  }
}

.Pricing {
  padding-top: $indent60;
  margin-bottom: $indent60;

  &Content {
    @include container;

    min-height: 800px;
    min-width: 300px;
    align-items: flex-start !important;
    display: flex;
    flex-direction: column;
    padding: $indent40 $indent60;
    background-color: $color-white;
    border-radius: 24px;
    margin-top: $indent60;
    box-shadow: 0 0 30px 0 $color-box-shadow;

    @media screen and (max-width: 800px) {
      width: 100%;
      flex-direction: column;
    }

    &Header {
      width: 100%;
      min-width: 610px;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      &Toggle {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      &Tabs {
        margin: 0 170px;
      }
    }
  }
}

@import 'src/sass/variables';

.PlanConfig {
  background: #ffffff;
  border-radius: 4px;
  padding: 26px 16px;
  margin-bottom: 25px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 25px;
    text-transform: capitalize;
  }

  &Form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    gap: 15px;
    margin-bottom: 34px;

    &Ages {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
      gap: 15px;
      margin-bottom: 34px;
      margin-right: 15px;
    }

    &Options {
      display: flex;
      justify-content: flex-start;
    }

    &Section {
      display: flex;
      align-items: center;
      margin: 15px 0;

      &Title {
        font-weight: 700;
        font-size: 18px;
        margin-right: 15px;
      }
    }

    &Table {
      margin-top: 34px;
      display: flex;

      &DropZone {
        margin-left: 24px;
      }
    }

    &Footer {
      display: flex;
      margin-top: 34px;

      button {
        width: 160px;
        margin-right: 10px;
      }
    }

    &Import {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ul {
        margin-bottom: 30px;

        li {
          text-align: left !important;
        }
      }
    }

    &File {
      font-weight: bold;
      margin-right: 5px;
      text-decoration: none;
      color: $hover-blue;
      text-transform: capitalize;

      &:hover {
        color: $default-color;
      }
    }
  }

  &Contribution {
    display: flex;
    margin: 15px 0;

    &Wrapper {
      margin: 15px 0;
    }
  }

  &Sliders {
    display: flex;
    margin-top: 24px;

    &Item {
      width: 260px;
      margin-right: 60px;

      @media screen and (max-width: 750px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 750px) {
      flex-direction: column;
    }
  }

  &Template {
    width: 100%;
    min-height: 120px;
    position: relative;
  }
}

.Modal {
  padding: 0 40px;

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 25px;
    text-align: left;
  }

  &Footer,
  &FooterCentered {
    display: flex;
    align-items: center;
    margin-top: 48px;

    button {
      width: 130px;
      margin-right: 10px;
    }
  }

  &FooterCentered {
    justify-content: center;
  }
}

.RenewalRates {
  display: flex;
  align-items: center;

  flex-direction: row;
  margin: 10px 0px;

  &Name {
    display: flex;
    min-width: 200px;
  }

  &Total {
    margin-left: 10px;
  }

  &Button {
    display: flex;
    align-items: center;

    &Item {
      margin-left: 10px;
      font-size: 14px;
      i {
        color: #2ecf00;
      }
    }

    button {
      height: 35px;
      width: 110px;
    }
  }
}

.UploadFileWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px 0;
  background-color: $default-gray;
  border: 1px solid $light-gray;
  border-radius: 4px;

  &:hover {
    background-color: #eeeeee;
  }

  cursor: pointer;
}

.UploadFile {
  display: flex;
  flex-direction: column;

  input {
    height: 100%;
    left: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
  }
}

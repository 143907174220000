@import 'src/sass/common';
@import 'src/sass/mixins/index.scss';

.Footer {
  width: 100%;
  display: flex;
  position: relative;
  padding: $indent80 $indent20;
  background-color: $color-purple-900;
  user-select: none;

  &Content {
    @include main-adaptive;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &SubTitle {
    @include h5;

    width: 100%;
    max-width: 535px;
    margin-top: $indent24;
  }

  &Navigation {
    display: flex;
    margin-top: $indent40;

    @include mobile {
      width: 100%;
      flex-direction: column;
    }
  }

  &SocialMedia {
    display: flex;
    margin-top: $indent40;

    img {
      margin-right: $indent40;
      -webkit-user-drag: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &AppVersion {
    @include h5;

    position: absolute;
    left: $indent24;
    bottom: $indent24;
  }
}

.FooterItem {
  margin-right: $indent32;
  cursor: pointer;

  @include mobile {
    margin-right: unset;
    margin-bottom: $indent24;
  }

  &:last-child {
    margin-right: 0;

    @include mobile {
      margin-bottom: 0;
    }
  }

  &Title {
    @include h5;

    color: $color-white;
    font-weight: 500;
  }
}

.swiper-slide {
  // padding: 12px;
  // background-color: #6210cc;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.swiper-pagination-bullet-active {
  background: #6210cc !important;
}

.swiper-scrollbar {
  display: none;
}

@import '../../sass/variables';

.Toggle {
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: $dark-gray;

  &Disabled {
    cursor: not-allowed;
  }

  // &Uni,
  // &UniActive {
  //   // margin-right: 15px;
  // }

  &UniActive {
    color: $monark-main-color;
  }

  // &Non,
  // &NonActive {
  //   // margin-left: 15px;
  // }

  &NonActive {
    color: $monark-main-color;
  }

  &Button {
    margin: 0 10px;
    display: inline-block;
    touch-action: pan-x;
    position: relative;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    &Input {
      height: 1px;
      width: 1px;
      position: absolute;
      border: 0;
      clip: rect(0 0 0 0);
      margin: -1px;
      overflow: hidden;
      padding: 0;
    }

    &Check,
    &Uncheck {
      height: 10px;
      width: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      line-height: 0;
      opacity: 0;
      transition: opacity 0.25s ease;
    }

    &Check {
      left: 8px;
    }

    &Uncheck {
      right: 10px;
      opacity: 1;
    }

    &Check span,
    &Uncheck span {
      height: 10px;
      width: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &ContainerUncheck,
    &ContainerCheck {
      height: 20px;
      width: 36px;
      padding: 0;
      border-radius: 30px;
      transition: all 0.2s ease;
    }

    &ContainerUncheck {
      background-color: #ccc;
    }

    &ContainerCheck {
      background-color: $hover-blue;
    }

    &Circle {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      background-color: #fafafa;
      box-sizing: border-box;
      transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      transition: all 0.25s ease;
    }

    &Checked &Check {
      opacity: 1;
    }
    &Checked &Uncheck {
      opacity: 0;
    }

    &Checked &Circle {
      left: 18px;
    }
  }
}

.AccountInfo {
  &Navigate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    button {
      width: 152px;
    }
  }
}

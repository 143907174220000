// Sizes
$large: 1440px;
$medium: 1260px;
$small: 740px;

$indent4: 4px;
$indent8: 8px;
$indent10: 10px;
$indent12: 12px;
$indent14: 14px;
$indent16: 16px;
$indent18: 18px;
$indent20: 20px;
$indent22: 22px;
$indent24: 24px;
$indent28: 28px;
$indent32: 32px;
$indent36: 36px;
$indent38: 38px;
$indent40: 40px;
$indent50: 50px;
$indent60: 60px;
$indent64: 64px;
$indent68: 68px;
$indent80: 80px;
$indent110: 110px;
$indent128: 128px;

// Colors
$color-black-600: #131313;
$color-black-500: #1a1a1a;
$color-black-400: #292d32;
$color-black: $color-black-500;

$color-white: #ffffff; // prev -> $white

$color-purple-900: #180531;
$color-purple-600: #6210cc;
$color-purple-500: #7833f3; // prev -> $monark-main-color
$color-purple-400: #7b61ff; // prev -> $purple-primary-ligth
$color-purple-100: #f5f0ff;
$color-purple: $color-purple-500;

$color-gray-500: #475467;
$color-gray-400: #868686; // prev -> $dark-gray
$color-gray-300: #98a2b3;
$color-gray-200: #e3e9ee;
$color-gray-100: #f8f8fa; // prev -> $default-gray
$color-gray: $color-gray-500;

$color-blue-900: #2c3e50; // prev -> $default-color
$color-blue-600: #3273dc; // prev -> $dark-blue
$color-blue-500: #209cee;
$color-blue-400: #1496e9; // prev -> $hover-blue
$color-blue-300: #209cee; // prev -> $light-blue
$color-blue: $color-blue-500;

$color-red-600: #ff0000; // prev -> $danger-red
$color-red-500: #e62222; // prev -> $default-red
$color-red-400: #ff2b56; // prev -> $hover-red
$color-red: $color-red-500;

$color-box-shadow: #c2c2c2;

// Durations
$transitionDuration: 0.3s;
